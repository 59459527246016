




































import { computed, defineComponent, ref, Ref, watch } from '@vue/composition-api'
import AtomDropdown, { DropDownDataType } from '@/components/atoms/AtomDropdown.vue'
import MypageTicketListSection from '@/components/MypageTicketListPage/MypageTicketListPane/MypageTicketListSection.vue'
import StoreUtil from '@/store/StoreUtil'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import useTicketSelectList from '@/components/MypageTicketListPage/hook/useTicketSelectList'
import usePurchasedTicketData from '@/components/hook/ticket/usePurchasedTicketData'

/**
 * マイページ: チケット一覧 セクション
 */
export default defineComponent({
  name: 'MypageTicketListContainerSection',
  components: { AtomDropdown, MypageTicketListSection },
  props: {
    openFilterId: {
      type: String,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const mypageTicketPageStore = StoreUtil.useStore('MypageTicketPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      selectedYear,
      isFetchedTicketPageData,
      selectedYearFilterOption,
      selectedPlaceFilterOption,
      digitalTicketsByYear,
      filteredDigitalTickets,
      areas,
    } = mypageTicketPageStore
    const { generateYearList, generatePlaceList } = useTicketSelectList()
    const { ownOrganization } = mypagePageStore
    const { userPurchasedTicket } = usePurchasedTicketData()

    const yearFilter = ref<HTMLInputElement | null>(null)
    const placeFilter = ref<HTMLInputElement | null>(null)
    /**  年のプルダウン */
    const yearList = ref<Array<DropDownDataType>>([])
    /**  場所のプルダウン */
    const placeList = ref<Array<DropDownDataType>>([])

    const yearFilterMaxHeight = computed(
      () => `calc(100svh - 36px - 56px - ${yearFilter.value?.getBoundingClientRect().top}px)`,
    )
    const placeFilterMaxHeight = computed(
      () => `calc(100svh - 36px - 56px - ${placeFilter.value?.getBoundingClientRect().top}px)`,
    )

    /**
     * ログインユーザーが購入したチケットに紐づくデジタルチケットデータ
     */
    const purchasedDigitalTickets = computed(() => {
      const eventUniqueKeys = userPurchasedTicket(
        filteredDigitalTickets.value,
        ownOrganization.value,
      ).ticketEventUniqueKeys.all

      return filteredDigitalTickets.value.filter((ticketEvent) =>
        eventUniqueKeys.some((uniqueKey) => uniqueKey === ticketEvent.uniqueKey),
      )
    })

    /**
     * 開催年フィルターの現在値が押下されたときの処理
     */
    const handleYearFilterCurrentClicked = () => {
      emit('onClickFilter', 'year')
    }

    /**
     * 開催年フィルターの選択肢が押下されたときの処理
     * @param option
     */
    const handleYearFilterOptionClicked = (option: DropDownDataType) => {
      emit('onClickFilterOption')
      if (selectedYearFilterOption.value.includes(option)) {
        // 年を未選択状態にはできない
        return
      }
      selectedYearFilterOption.value = [option]
      selectedYear.value = Number(option.id)

      // 場所の選択状態をリセットし、場所のプルダウンを再生成する
      selectedPlaceFilterOption.value = []
      placeList.value = generatePlaceList(
        areas.value,
        digitalTicketsByYear.value?.[selectedYear.value],
      )
    }

    /**
     * 場所フィルターの現在値が押下されたときの処理
     */
    const handlePlaceFilterCurrentClicked = () => {
      emit('onClickFilter', 'place')
    }

    /**
     * 場所フィルターの選択肢が押下されたときの処理
     * @param optionData
     */
    const handlePlaceFilterOptionClicked = (optionData: DropDownDataType) => {
      if (selectedPlaceFilterOption.value.includes(optionData)) {
        selectedPlaceFilterOption.value = selectedPlaceFilterOption.value.filter(
          (item) => item !== optionData,
        )
      } else {
        selectedPlaceFilterOption.value = [...selectedPlaceFilterOption.value, optionData]
      }
      emit('onClickFilterOption')
    }

    watch(
      () => isFetchedTicketPageData.value,
      (isFetched) => {
        if (isFetched) {
          // 年/場所のプルダウンを生成
          yearList.value = generateYearList()
          placeList.value = generatePlaceList(
            areas.value,
            digitalTicketsByYear.value?.[selectedYear.value],
          )

          const year = yearList.value.find((v) => v.id === selectedYear.value)
          if (year) {
            selectedYearFilterOption.value = [year]
          }
        }
      },
    )

    return {
      // MypageTicketPageStore
      isFetchedTicketPageData,
      selectedYearFilterOption: selectedYearFilterOption as Ref<Array<DropDownDataType>>,
      selectedPlaceFilterOption: selectedPlaceFilterOption as Ref<Array<DropDownDataType>>,
      purchasedDigitalTickets: purchasedDigitalTickets as Ref<Array<DigitalTicketDataDocument>>,
      // MypageTicketListContainerSection
      yearFilterMaxHeight,
      placeFilterMaxHeight,
      yearList,
      placeList,
      handleYearFilterCurrentClicked,
      handleYearFilterOptionClicked,
      handlePlaceFilterCurrentClicked,
      handlePlaceFilterOptionClicked,
    }
  },
})
