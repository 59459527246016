import Vue from 'vue'
import type VueRouter from 'vue-router'
import dayjs from 'dayjs'
import '@/assets/css/main.scss'
import VueCompositionAPI from '@vue/composition-api'
import VueVirtualScroller from 'vue-virtual-scroller'
import DatePicker from 'vue2-datepicker'
import Loading from 'vue-loading-overlay'
import { configure, extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, max } from 'vee-validate/dist/rules'
import VueObserveVisibility from 'vue-observe-visibility'
import I18n from '@/locales/I18n'
import 'vue-loading-overlay/dist/vue-loading.css'
import StoreUtil from '@/store/StoreUtil'
import App from '@/App.vue'
import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'
import AppConfigStore from '@/store/stores/pageStore/common/AppConfigStore'
import NTPClient from '@/util/ntp/NTPClient'

/**
 * PC, モバイルの共通のmainの処理を実行する。
 *
 * @param router PC または、モバイル用の Vue-Router
 */
const main = (router: VueRouter) => {
  // Loggerを初期化する
  Logger.init()

  Logger.info(`Start SFgo. platform: ${DeviceInfo.PLATFORM}`)

  // NTPサーバーから時刻を取得し、デバイスの時刻のずれを設定する
  if (NTPClient.available()) {
    NTPClient.init()
    Logger.debug('Start to get time from NTP')
    NTPClient.getTime().then((ntpTime) => {
      const now = Date.now()
      AppConfigStore.value.setTimeDeviation(now - ntpTime)
      Logger.info(`Success to init time deviation: ${AppConfigStore.value.getTimeDeviation()}`)
    })
  }

  Vue.config.productionTip = false
  // Vue-Composition APIを利用する
  Vue.use(VueCompositionAPI)

  // VeeValidation 設定
  extend('required', required)
  extend('max', max)
  extend('email', email)
  extend('passwordFormat', {
    message: I18n.t('validations.messages.passwordFormat', { braces: '{' }) as string,
    validate: (value) => {
      if (!value || !value.length) return true
      const passwordRegexp = /^[a-zA-Z0-9-_.@!#$%&'*+/=?^`{|}~]{8,32}$/
      return passwordRegexp.test(value)
    },
  })
  extend('validBirthDay', {
    validate: (value) => {
      if (!value || !value.length) return true

      if (dayjs(value).format('YYYY/MM/DD') !== value) {
        /**
         * 存在しない日付の場合はエラー
         * 条件は以下を参考にした
         * https://github.com/iamkun/dayjs/issues/320#issuecomment-537885327
         */
        return false
      }

      const todayMillisec = new Date(new Date().toDateString()).getTime()
      const birthDayMillisec = new Date(new Date(value).toDateString()).getTime()
      const threeYearsAgo = dayjs(todayMillisec).subtract(3, 'year').valueOf()
      // 3歳未満はエラー
      return threeYearsAgo >= birthDayMillisec
    },
  })
  extend('zipcodeFormat', {
    validate: (value) => {
      if (!value || !value.length) return true
      const regex = new RegExp(/^[0-9]{7}$/)
      return regex.test(value)
    },
  })

  configure({
    // this will be used to generate messages.
    defaultMessage: (field, values) => {
      const veeValues = values
      veeValues._field_ = I18n.t(`validations.fields.${field}`) as string
      return I18n.t(`validations.messages.${values._rule_}`, veeValues).toString()
    },
  })
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)

  // ストアを作成する
  StoreUtil.generateStore()

  Vue.use(Loading)
  // 速度改善のため仮想スクロール（vue-virtual-scroller）を導入
  Vue.use(VueVirtualScroller)
  // vue-datepicker
  Vue.use(DatePicker)
  // vue-observe-visibility
  Vue.use(VueObserveVisibility)

  // どのcomponentからでも利用できるようにするために、routerとloadingを注入する
  App.provide = () => ({ router, loading: Vue.$loading })

  new Vue({
    i18n: I18n,
    router,
    render: (h) => h(App),
  }).$mount('#app')
}

export default main
