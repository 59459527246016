import LoginStore from '@/store/stores/loginStore/LoginStore'
import { Permission } from '@/store/stores/loginStore/PermissionType'
import { AccessControlList } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * Documentのベースになるabstract class
 * これを継承する際には以下の7つを継承先のクラスに実装する
 *
 * 1. constructor  - Documentに初期値を設定する処理を定義する。以下の例のように`mergeToThis`関数を呼び出すだけで良い
 * 2. _path         - CollectionModuleでfetchやsaveをする際に利用するpathを文字列で定義する。
 *                    ここで設定しない場合は、CollectionModule#fetchなどの実行時に動的にpathを指定することもできる
 * 3. _removeOrgPath  - path の前に組織IDを付与しない場合は true を設定する。デフォルトは false
 * 4. excludeKeys  - CollectionModuleでsaveをする際に、dataに含まないプロパティのキーの配列を文字列で定義する
 * 5. idAttr       - 主キーの名前を文字列で定義する。デフォルトは_idになる
 * 6. makeSaveData - save時にgetAPISchema後にAPISchemaを変更したい場合は、サブクラスでこの関数を実装することで変更することができる
 *
 * 定義例は以下のような感じ
 *
 * <pre><code>
 *import { DocumentWrapper } from 'store/document/DocumentWrapper'
 *
 *export class GameMatchDocument extends DocumentWrapper {
 *  constructor(initProps?: Partial<GameMatchDocument>) {
 *   super(initProps)
 *   this.mergeToThis(initProps)
 *  }
 *
 * _path = 'data/record/game_match'
 * excludeKeys = ['broadcast']
 * idAttr: keyof this = 'matchId'
 *
 * matchId: string | null = null
 * scheduleDate: number | null = null
 * broadcast: boolean = false
 *}
 *
 * </code></pre>
 */
export default class DocumentWrapper {
  /**
   * 引数で指定したオブジェクトを、このインスタンスにマージする。
   * @param addProps マージするオブジェクト
   */
  constructor(addProps?: Partial<DocumentWrapper>) {
    this.mergeToThis(addProps)
  }

  /**
   * バックエンドAPIのパスを指定する。
   * このパスはCollectionModule#fetch / save /delete時のパスとして利用されるので
   * 継承先のDocumentでそれぞれのDocumentに応じたPathを実装する必要がある
   * これを実装しない場合、もしくは、documentとpathが1対1の関係ではない場合、実行時に動的にpathを変更したい場合などは
   * CollectionModule#fetch / save / delete の引数でpathもしくは完全なurlを実行時に指定することができる
   * 詳しくはCollectionTypes#BaseOptionsを参照
   */
  _path = ''

  /**
   * バックエンドAPIのパスに組織IDが含まれない場合 true を指定する。
   */
  _removeOrgPath = false

  /**
   * バックエンドAPIにpost / putする際に取り除くキーのリストを指定する
   * DocumentWrapperで定義したものに関して指定している
   */
  private __DEFAULT_EXCLUDE_KEYS__ = [
    'id',
    'idAttr',
    '__DEFAULT_EXCLUDE_KEYS__',
    'excludeKeys',
    '_path',
    '_id',
    '_organization',
    '_createdBy',
    '_lastModifiedBy',
    '_createdDate',
    '_lastModifiedDate',
  ]

  /**
   * バックエンドAPIにpost / putする際に取り除くキーのリストを指定する
   * サブクラスで実装する
   */
  protected excludeKeys: string[] = []

  /**
   * 主キーのキー名
   * ここで設定したキーの値を`id`プロパティで取得できる
   */
  protected idAttr = '_id'

  /**
   * このデータを参照するために必要な権限のリスト
   */
  protected requiredPermissions: Array<Permission> = []

  /**
   * 主キーの値を取得します
   */
  get id(): string | null {
    const idAttr = this.idAttr as keyof this
    return this.idAttr ? (this[idAttr] as unknown as string) : null
  }

  /**
   * excludeKeysで指定されたキー以外のプロパティのみを抜き出して新しいオブジェクトを作成する
   * バックエンドAPIに保存する際に使用する
   */
  getAPISchema(): Partial<this> {
    let res = Object.create(null)
    Object.getOwnPropertyNames(this).forEach((key) => {
      if (key === '__ob__') return
      if (this.__DEFAULT_EXCLUDE_KEYS__.includes(key)) return
      if (this.excludeKeys.includes(key)) return
      const fieldName = key as keyof this
      if (typeof this[fieldName] === 'function') return
      if (this[fieldName] === undefined) return
      res[fieldName] = this[fieldName]
    })
    if (this.makeSaveData) {
      res = this.makeSaveData(res)
    }
    return res
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */

  /**
   * バックエンドサーバに保存する際に、Documentごとに独自のパース処理を行う場合は
   * この関数をサブクラスで実装する
   */
  // eslint-disable-next-line no-use-before-define
  protected makeSaveData?: <T extends DocumentWrapper>(self: Partial<T>) => { [key: string]: any }

  /**
   * 自分自身のシャローコピーを返す
   */
  clone(): this {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
  }

  /**
   * このデータが、ログインユーザーの組織に紐付いたデータかどうかを返す
   * @return {boolean} 自組織に紐付いたデータかどうか
   */
  get isOwnOrgData() {
    const ownOrgId = LoginStore.value.orgId
    return !this._organization || ownOrgId === this._organization
  }

  /**
   * newしたときに初期値をDocumentに設定するための関数
   * Documentの方では以下のようなコンストラクタを実装する
   * 以下はGameMatchDocumentの場合
   * <pre><code>
   *   constructor(initProps?: Partial<GameMatchDocument>){
   *     super()
   *     this.mergeToThis(initProps)
   *   }
   * </code></pre>
   *
   * これを実装したDocumentはnewする際にオブジェクトで初期値を設定することができる
   * 以下のような感じ
   * const game_match = new GameMatchDocument({matchId: 'masterId'})
   *
   * @param obj マージするオブジェクト
   *
   */
  protected mergeToThis<T extends DocumentWrapper>(obj?: Partial<T>): void {
    if (!obj) return
    Object.assign(this, obj)
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  /**
   * 主キーの値
   */
  _id?: string

  /**
   * 組織ID
   */
  _organization?: string

  /**
   * 作成者のuserId
   */
  _createdBy?: string

  /**
   * 最終更新者のuserId
   */
  _lastModifiedBy?: string

  /**
   * 作成日時
   */
  _createdDate?: number

  /**
   * 更新日時
   */
  _lastModifiedDate?: number

  /**
   * バージョン
   * ドキュメントの更新回数
   */
  _version?: number

  /**
   * 削除フラグ
   */
  _deletedFlag?: boolean

  /**
   * 公開範囲
   */
  acl?: AccessControlList

  /**
   * 論理削除されたデータかどうかを判定する。
   */
  get deleted() {
    return this._deletedFlag
  }
}
