import axios, { AxiosError, AxiosResponse, Method } from 'axios'
import _ from 'lodash'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import APIResponse from '@/util/APIResponse'
import Logger from '@/util/logger/Logger'

/* eslint-disable @typescript-eslint/no-explicit-any */
type RequestProps = {
  method: Method
  url: string
  query?: { [key: string]: any }
  data?: { [key: string]: any }
}

/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * API呼び出しを行うための機能を提供するクラス。
 */
class APIAccessor {
  /**
   * API 呼び出しを行う。
   * @param param リクエストパラメタ
   * @param accessToken アクセストークン
   * @return 処理完了を待機するためのPromise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async request<T = any>(
    param: RequestProps,
    accessToken: string | null | undefined,
  ): Promise<APIResponse<T>> {
    const url =
      param.query && Object.keys(param.query).length > 0
        ? `${param.url}?${APIAccessor.paramsSerializer(param.query)}`
        : param.url

    try {
      const res = await axios({
        method: param.method,
        url,
        data: param.data,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          'X-FLUX-API-TOKEN': accessToken ?? '',
        },
        withCredentials: true,
      })
      return new APIResponse(res)
    } catch (e) {
      Logger.error('APIAccessor#request: Failed to request.', e)
      // ネットワークエラーのときはresponseがない時がある
      if (!axios.isAxiosError(e)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new APIResponse(e as any)
      }
      let _accessToken = accessToken

      if (e.response?.status === 401) {
        if (e.response.data.error_code === APIResponse.ERROR_CODE.SESSION_LIMIT_EXCEEDED) {
          // 同時ログイン数上限を超えた場合
          LoginStore.value.concurrentSessionLimitExceeded?.(e.response)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return new APIResponse(e as any)
        }
        // 認証トークンが失効していた場合、refreshTokenを使ってもう一度認証を試みる
        _accessToken = (await LoginStore.value.refreshAuthLogic?.()) || null

        const response = await axios({
          method: param.method,
          url,
          data: param.data,
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            'X-FLUX-API-TOKEN': _accessToken ?? '',
          },
          withCredentials: true,
        }).catch((error: AxiosError) => error)
        return new APIResponse(response as AxiosResponse | AxiosError)
      }
      return new APIResponse(e.response as AxiosResponse | AxiosError)
    }
  }

  /**
   * 検索クエリのURLエンコードを行う。
   * <p>
   *   クエリに対してaxios標準のURLエンコード処理を行なった場合、
   *   AWS の Application Load Balancer が 400エラーとする問題があったため、
   *   既存のaxiosのURLエンコード処理(axios/lib/helpers/buildURL.js)を
   *   このメソッドの処理で上書きする。
   *   ※encodeURIComponentのみを利用してURLエンコードする
   *   以下のpullリクエストがaxiosに取り込まれれば不要になる予定
   *   https://github.com/axios/axios/pull/2563
   * </p>
   * @param query 検索リクエストに指定されたクエリオブジェクト
   * @return URLエンコードされた文字列
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static paramsSerializer(query: { [key: string]: any }): string {
    const parts: string[] = []

    Object.keys(query).forEach((name) => {
      let key = name
      let val = query[key]
      if (val === null || typeof val === 'undefined') {
        return
      }

      if (_.isArray(val)) {
        key = `${key}[]`
      }

      if (!_.isArray(val)) {
        val = [val]
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      val.forEach((value: any) => {
        let v = value
        if (_.isDate(v)) {
          v = v.toISOString()
        } else if (_.isObject(v)) {
          v = JSON.stringify(v)
        }
        if (key === 'count') {
          // countの場合はvalueの指定が不要
          parts.push(`${encodeURIComponent(key)}`)
        } else {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
        }
      })
    })

    return parts.join('&')
  }
}

export default APIAccessor
