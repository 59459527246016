import { computed, toRefs, watch } from '@vue/composition-api'
import { throttle } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import Logger from '@/util/logger/Logger'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import useHistory from '@/store/hook/useHistory'
import useAddInfoToRadioData from '@/components/hook/useAddInfoToRadioData'

/**
 * 選手毎の無線更新データを保持するマップ。
 * 選手の車両番号をキーにして、その選手の無線交信データを値として保持する。
 */
export type RadioDataHashedByCarNoType = Map<string, RadioDataDocument | null>

/**
 * 選手の無線データ情報の取得を行うための処理を取得する。
 */
export default function useRadio(watchMovieTime = false) {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
  const { addPlayerDataToRadioData } = useAddInfoToRadioData()

  const {
    players,
    selectedPlayerId,
    selectedPlayer,
    radioDataStore,
    computeActualTimeForVideoPlaybackPosition,
    currentMovieInfo,
  } = raceVideoPageStore

  const { saveMissionHistory } = useHistory()

  const { videoPlayer } = toRefs(raceVideoPageStore.raceVideoPageState)

  // 動画再生位置をwatchするため、動画再生ステータスを取得
  const { videoStatus } = toRefs(raceVideoPageStore.raceVideoPageState)

  /**
   * 全ての選手の無線交信データを取得する。
   * この無線交信データは無線の音声の生成開始日時順にソートされる。
   */
  const getAllPlayerRadioDataSet = computed((): Array<RadioDataDocument> => {
    const allRadioDataSet: Array<RadioDataDocument> = []
    radioDataStore.radios.value.forEach((radioDataDocument) => {
      const targetPlayer = players.value.find((player) =>
        player.isRelatedCar(String(radioDataDocument.car_no)),
      )
      if (targetPlayer) {
        const radioData = addPlayerDataToRadioData(radioDataDocument, targetPlayer)
        if (radioData) {
          allRadioDataSet.push(radioData)
        }
      }
    })
    return allRadioDataSet.sort((a, b) => a.clip_start_time - b.clip_start_time)
  })

  /**
   * 動画の再生位置に存在する現在選択されているドライバーの無線交信データを取得する。
   */
  const getCurrentPlayerRadioDataAtTime = computed(() => {
    const currentTime = computeActualTimeForVideoPlaybackPosition(false)
    const radioDataSet = radioDataStore.getRadioDataSetAtTime(currentTime, 1)
    return radioDataSet.find((playerRadioData) =>
      selectedPlayer.value?.isRelatedCar(`${playerRadioData.car_no}`),
    )
  })
  /**
   * 現在の動画の再生位置に存在する無線交信データを取得する。
   * この無線交信データは無線の音声の生成開始日時順にソートされる。
   */
  const getCurrentAllPlayerRadioDataSet = computed(() => {
    const currentTime = computeActualTimeForVideoPlaybackPosition(false)
    const radioDataSet = radioDataStore.getRadioDataSetAtTime(currentTime)

    const currentPlayerRadioDataSet: Array<RadioDataDocument> = []
    radioDataSet.forEach((radioDataDocument) => {
      const targetPlayer = players.value.find((player) =>
        player.isRelatedCar(String(radioDataDocument.car_no)),
      )
      if (targetPlayer) {
        const radioData = addPlayerDataToRadioData(radioDataDocument, targetPlayer)
        if (radioData) {
          currentPlayerRadioDataSet.push(radioData)
        }
      }
    })
    return currentPlayerRadioDataSet.sort((a, b) => a.clip_start_time - b.clip_start_time)
  })

  /**
   * 現在の動画の再生位置に存在する無線交信データを、選手毎に分割したマップデータとして取得する。
   */
  const getCurrentPlayerRadioDataSetByPlayer = computed((): RadioDataHashedByCarNoType => {
    const currentRadioDataSet = getCurrentAllPlayerRadioDataSet.value

    return currentRadioDataSet.reduce((map, playerRadioData) => {
      if (playerRadioData.car_no) {
        map.set(`${playerRadioData.car_no}`, playerRadioData)
      }
      return map
    }, new Map() as RadioDataHashedByCarNoType)
  })

  /**
   * 現在選択されている選手の全ての無線交信データのリストを取得する。
   */
  const getCurrentSelectedPlayerRadioDataSet = computed(() => {
    if (!selectedPlayer.value) {
      return []
    }
    return getAllPlayerRadioDataSet.value.filter((playerRadioData) =>
      selectedPlayer.value?.isRelatedCar(`${playerRadioData.car_no}`),
    )
  })

  /**
   * 無線交信データのLIVE再生の有効/無効状態を取得する。
   */
  const { livePlayerRadioEnabled } = radioDataStore

  /**
   * 無線交信データの音声を再生する。
   * 無線視聴の、ミッションの操作ログを登録する
   * @param playerRadioData 無線交信データ
   */
  const playRadioAudio = (playerRadioData: RadioDataDocument) => {
    saveMissionHistory(
      UserStore.value.user.value._organization || '',
      'manage_organization',
      'watch_radio',
    )
    radioDataStore.playRadioAudio(playerRadioData)
  }

  /**
   * 無線交信データの音声を再生し、その無線が発生した動画の再生位置にシークする。
   * @param playerRadioData 無線交信データ
   */
  const playRadioAndSeekMovie = async (playerRadioData: RadioDataDocument) => {
    if (!livePlayerRadioEnabled.value || playerRadioData.sid !== selectedPlayerId.value) {
      /** 以下の場合、再生位置にシークした後に自動で無線が再生されないため、ここで無線を再生する処理を行う
       * LIVE自動再生OFF状態で無線の動画再生ボタンをタップ
       * 選択中(=オンボード映像再生中)ではない選手の無線の動画再生ボタンをタップ
       */
      await playRadioAudio(playerRadioData)
    }

    if (currentMovieInfo.value.recordingStartTime) {
      // 再生位置は、動画のライブ配信開始日時から、対象の無線の音声データ生成開始までの時間の差を取ることで求める
      const timeDiff = playerRadioData.clip_start_time - currentMovieInfo.value.recordingStartTime
      await videoPlayer.value?.play()
      await videoPlayer.value?.setCurrentTime(timeDiff / 1000)
    }
  }

  /**
   * 無線交信データの音声の再生を停止する。
   */
  const stopRadioAudio = async () => {
    await radioDataStore.pauseRadioAudio()
  }

  /**
   * 現在、無線交信データを再生しているかどうかを判定する。
   */
  const isPlayingRadio = computed(() => radioDataStore.isPlayingRadio.value)

  /**
   * 無線交信データを再生する。
   * 他に再生中の無線交信データがあった場合、その再生を停止し、指定された無線交信データを再生する。
   * 指定された無線交信データが再生中の場合、停止して処理を終了する。
   * seekフラグにtrueを指定された場合、動画の再生位置を、指定された無線が発生した場所に変更する。
   *
   * @param playerRadioData 無線交信データ
   * @param seek 動画の再生位置をシークするかどうか
   */
  const playRadioDataAudio = async (playerRadioData: RadioDataDocument, seek = false) => {
    if (isPlayingRadio.value) {
      const isSameRadio = playerRadioData.id === radioDataStore.getSelectedRadioData()?.id
      await stopRadioAudio()
      if (isSameRadio) {
        return
      }
    }

    if (seek) {
      await playRadioAndSeekMovie(playerRadioData)
    } else {
      playRadioAudio(playerRadioData)
    }
  }
  if (watchMovieTime) {
    /**
     * 動画の再生位置を監視し、再生位置に無線交信データが存在する場合、自動的に再生する。
     * 自動再生は、無線交信データのLIVE再生の有効な場合のみ行う。
     * 無線交信データが再生中の場合は、自動再生は実施しない。
     */
    watch(
      videoStatus,
      throttle(async () => {
        if (!livePlayerRadioEnabled.value || isPlayingRadio.value) {
          // 無線のLIVE再生が無効、または、他の無線を再生中の場合、スキップする
          return
        }

        const currentPlayerRadioData = getCurrentPlayerRadioDataAtTime.value
        if (currentPlayerRadioData) {
          // 直前に再生した無線交信データが再度検出される場合があるため、同じデータの場合は自動再生しないようにする
          await playRadioDataAudio(currentPlayerRadioData, false)
          Logger.debug(`watch: Play radio audio. data: ${currentPlayerRadioData}`)
        }
      }, 1000),
      { deep: true },
    )
  }

  return {
    getAllPlayerRadioDataSet,
    getCurrentAllPlayerRadioDataSet,
    getCurrentSelectedPlayerRadioDataSet,
    getCurrentPlayerRadioDataSetByPlayer,
    getCurrentPlayerRadioDataAtTime,
    playRadioDataAudio,
    livePlayerRadioEnabled,
    isPlayingRadio,
  }
}
