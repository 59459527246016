


















import { defineComponent, ref, toRefs, watch } from '@vue/composition-api'
import { throttle } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import useVideoPlayer from '@/components/hook/useVideoPlayer'
import Env from '@/util/Env'
import Logger from '@/util/logger/Logger'

/**
 * レース動画再生画面 サブ動画
 *
 * 動画再生画面で、メイン動画プレーヤーが再生していないアングルを再生する。
 * メイン動画プレーヤーが中継映像を再生している場合、オンボード映像を再生し、
 * メイン動画プレーヤーがオンボード映像を再生している場合、中継映像を再生する。
 *
 * メイン動画プレーヤーと再生状態を同期するため、メイン動画プレーヤーの以下のイベントを補足し、再生位置とアングルを調整する。
 * - シークイベント
 * - 動画URL変更イベント
 * - 動画プレーヤーの再生状態(再生中/一時停止中)変更イベント
 * - 動画プレーヤーのスロー再生切り替え
 */
export default defineComponent({
  name: 'SubVideoPane',
  setup(props, context) {
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const {
      officialMovieInfo,
      isOfficialMovie,
      currentPlayerOnBoardMovieInfo,
      computeOpponentAngleCurrentTime,
      isLive,
      selectedPlayerId,
      videoSlow,
    } = raceVideoPageStore

    const { videoPlayerStatus, videoStatus, videoPlayer } = toRefs(
      raceVideoPageStore.raceVideoPageState,
    )

    const loginStore = StoreUtil.useStore('LoginStore')

    const videoInvalid = ref(false)

    const targetMovieInfo = isOfficialMovie.value
      ? currentPlayerOnBoardMovieInfo.value
      : officialMovieInfo.value

    /**
     * サブ動画の映像情報がない場合、サブ動画プレーヤーが存在していないため、映像情報が存在するドライバーを選択したとしてもサブ映像の切り替えができない。
     * そのため、サブ動画の映像が存在しない場合は、本コンポーネントを再構築するように親に伝える。
     * 再構築したタイミングでtargetMovieInfoが存在する場合は、サブ動画プレイヤーが作成され、以降サブ動画の切り替えが可能になる。
     */
    watch(
      () => selectedPlayerId.value,
      async () => {
        if (!targetMovieInfo) {
          context.emit('handlerReCreateElement')
        }
      },
    )

    if (!targetMovieInfo) {
      videoInvalid.value = true
      return {
        videoInvalid,
      }
    }

    /**
     * サブ動画プレーヤーを作成する。
     * サブの動画の音声は無しにして、メインの映像の音声のみ流れるようにする。
     */
    const { videoPlayer: subVideoPlayer, videoPlayerStatus: subVideoPlayerStatus } = useVideoPlayer(
      {
        id: 'sfgo-sub-video',
        movieUrl: targetMovieInfo.playlistPath,
        live: isLive.value,
        playerType: Env.isMock ? 'Browser' : undefined,
        refreshToken: loginStore.refreshToken,
        cookies: loginStore.movieCookies,
        currentTime: videoStatus.value?.currentTime,
        muted: true,
        readVideoTrackTime: targetMovieInfo.videoTrackTimeEnabled,
      },
    )

    raceVideoPageStore.setSubVideoPlayer(subVideoPlayer)
    videoPlayer.value?.addSeekEventListener(async () => {
      const calculatedCurrentTime = computeOpponentAngleCurrentTime() ?? 0
      await subVideoPlayer.setCurrentTime(calculatedCurrentTime)
      if (videoPlayerStatus.value?.playStatus === 'PLAY') {
        await subVideoPlayer.play()
      }
    })

    if (videoPlayerStatus.value?.playStatus === 'PLAY') {
      subVideoPlayer.play()
    }

    // メイン動画プレーヤーの再生位置をwatchする
    watch(
      () => videoStatus.value?.currentTime,
      throttle(async (currentTime) => {
        if (currentTime) {
          const opponentAngleCurrentTime = computeOpponentAngleCurrentTime()
          const duration = await subVideoPlayer.duration()
          // サブ動画の動画長がメイン動画の再生位置よりも短くなってしまうことがあるため、3秒ほど余裕を持たせる
          const adjustedDuration = duration + 3
          Logger.debug(
            `opponentAngleCurrentTime: ${opponentAngleCurrentTime}, duration: ${duration}`,
          )
          videoInvalid.value =
            duration !== 0 &&
            (opponentAngleCurrentTime === undefined ||
              opponentAngleCurrentTime < 0 ||
              opponentAngleCurrentTime > adjustedDuration)
          const calculatedCurrentTime = opponentAngleCurrentTime ?? 0
          if (videoInvalid.value) {
            // 指定された再生位置が、動画の長さよりも大きい、または、再生位置を計算できない場合、プレーヤーを非表示にする(NO VIDEOを表示する)
            await subVideoPlayer.hide()
          } else {
            await subVideoPlayer.show()
            const diff = Math.abs((await subVideoPlayer.currentTime()) - calculatedCurrentTime)
            if (diff > 1.0) {
              // メインの映像の再生位置と3秒以上のずれがある場合、計算した再生位置に移動する
              await subVideoPlayer.setCurrentTime(calculatedCurrentTime)
              Logger.debug(`SubVideoPane#watch: Playback position adjusted. diff: ${diff}`)
            }
          }
          if (videoPlayerStatus.value?.playStatus !== subVideoPlayerStatus.playStatus) {
            if (videoPlayerStatus.value?.playStatus === 'PLAY') {
              subVideoPlayer?.play()
            } else {
              subVideoPlayer?.pause()
            }
          }
        }
      }, 1000),
    )

    // メイン動画プレーヤーの動画URLの変更をwatchする
    watch(
      () => videoStatus.value?.movieUrl,
      async () => {
        // 動画URLが変更された場合、サブ動画プレーヤーの再生対象の動画を切り替える
        const newMovieInfo = isOfficialMovie.value
          ? currentPlayerOnBoardMovieInfo.value
          : officialMovieInfo.value
        if (newMovieInfo?.playlistUrl) {
          const currentTime = computeOpponentAngleCurrentTime()
          if (currentTime === undefined) {
            await subVideoPlayer.hide()
          } else {
            await subVideoPlayer.show()
            await subVideoPlayer.setMovieUrl(
              newMovieInfo?.playlistUrl,
              currentTime,
              undefined,
              newMovieInfo?.videoTrackTimeEnabled,
            )
          }
        } else {
          await subVideoPlayer.hide()
        }
      },
    )
    // 動画プレーヤーの再生状態(再生中/一時停止中)の変更をwatchする
    watch(
      () => videoPlayerStatus.value?.playStatus,
      (playStatus) => {
        if (playStatus === 'PAUSE') {
          subVideoPlayer.pause()
        } else {
          subVideoPlayer.play()
        }
      },
    )
    // 動画プレーヤーのスロー再生の切り替えをwatchする
    watch(
      () => videoSlow.value,
      async (isVideoSlow) => {
        if (isVideoSlow) {
          await subVideoPlayer?.setPlaybackRate(0.5)
        } else {
          await subVideoPlayer?.setPlaybackRate(1.0)
        }
      },
    )
    return {
      videoInvalid,
    }
  },
})
