import { reactive } from '@vue/composition-api'
import axios, { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'
import { StoreBase, ValueType } from '@/store/StoreBase'
import { OrganizationDetail, VideoTranscodeType } from '@/store/stores/loginStore/LoginTypes'
import Logger from '@/util/logger/Logger'
import APIResponse, { isAxiosError } from '@/util/APIResponse'
import SignedCookie, { SignedCookiePath } from '@/@types/SignedCookie'
import SecureKeyStore from '@/util/secureKeyStore/SecureKeyStore'
import { RoleType } from '@/store/stores/pageStore/common/RoleType'
import DeviceInfo from '@/util/DeviceInfo'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAxiosResponse = (response: any): response is AxiosResponse => !!response.status

/**
 * ログイン情報の初期状態
 */
const initialState = {
  baseUrl: '',
  loginId: '',
  loginName: '',
  displayName: '',
  password: '',
  accessToken: '',
  refreshToken: '',
  orgId: '',
  userId: '',
  orgDetail: null as OrganizationDetail,
  viewQuality: null as VideoTranscodeType,
  cookies: {} as { [key in SignedCookiePath]: Array<SignedCookie> },
  movieCookies: [] as Array<SignedCookie>,
  fileCookies: [] as Array<SignedCookie>,
  imageCookies: [] as Array<SignedCookie>,
  audiosCookies: [] as Array<SignedCookie>,
  sessionExpired: false,
  sessionLimitExceeded: false,

  /**
   * ログインユーザーのロール
   */
  userRoles: [] as Array<RoleType>,
}
/* eslint-disable class-methods-use-this */
class LoginStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })
    const store = {
      /**
       * ログインされているかどうか
       */
      get isLogin() {
        return !!state.accessToken
      },
      /**
       * ログインに成功したBaseUrl
       * ログイン後のAPIリクエストにはこのbaseUrlを利用する
       */
      get baseUrl() {
        return state.baseUrl
      },
      /**
       * 組織IDを返す
       */
      get orgId() {
        return state.orgId
      },
      /**
       * ユーザーIDを返す
       */
      get userId() {
        return state.userId
      },
      /**
       * アクセストークンを返す
       */
      get accessToken() {
        return state.accessToken
      },
      /**
       * リフレッシュトークンを返す
       */
      get refreshToken() {
        return state.refreshToken
      },
      /**
       * ログインIDを返す
       */
      get loginId() {
        return state.loginId
      },
      /**
       * パスワードを返す
       */
      get password() {
        return state.password
      },
      /**
       * ログイン名を返す
       */
      get loginName() {
        return state.loginName
      },
      /**
       * ログイン中のユーザーのロールを返す
       */
      get userRoles() {
        return state.userRoles
      },
      /**
       * Cookieを返す
       */
      get cookies() {
        return state.cookies
      },
      /**
       * Pathが '/moives' の署名Cookieを返す
       * @return 署名Cookieの配列
       */
      get movieCookies() {
        return state.movieCookies
      },
      /**
       * Pathが '/files' の署名Cookieを返す
       * @return 署名Cookieの配列
       */
      get fileCookies() {
        return state.fileCookies
      },
      /**
       * Pathが '/img' の署名Cookieを返す
       * @return 署名Cookieの配列
       */
      get imageCookies() {
        return state.imageCookies
      },
      /**
       * Pathが '/audios' の署名Cookieを返す
       * @return 署名Cookieの配列
       */
      get audiosCookies() {
        return state.audiosCookies
      },
      /**
       * セッション有効期限切れかどうか
       */
      get isSessionExpired() {
        return state.sessionExpired
      },
      /**
       * セッション上限数超えかどうか
       */
      get isSessionLimitExceeded() {
        return state.sessionLimitExceeded
      },
      /**
       * 視聴画質を返す
       */
      get viewQuality() {
        return state.viewQuality
      },
      /**
       * 視聴画質を格納する
       * @param value 視聴画質
       */
      set viewQuality(value: VideoTranscodeType) {
        state.viewQuality = value
      },
      /**
       * 認証API のエンドポイントを返す
       */
      get authApiEndpoint() {
        const url = process.env.VUE_APP_API_BASE_URL as string
        return `${url}/auth`
      },
      /**
       * ユーザー名とパスワードを用いてログイン処理を行う
       * @param loginName ログイン名
       * @param password パスワード
       */
      async login(loginName: string, password: string) {
        let isSuccess = false
        let apiResponse: APIResponse | undefined
        try {
          const { authApiEndpoint } = this

          let additionalData = {}
          if (DeviceInfo.device) {
            // 同時ログイン数上限チェックを機能させるため、デバイストークンを設定する
            // @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3334701172
            additionalData = {
              deviceToken: DeviceInfo.device.uuid,
            }
            Logger.debug(`LoginStore#login: additionalData: ${JSON.stringify(additionalData)}`)
          }

          const data = qs.stringify({
            grant_type: 'password',
            username: loginName,
            password,
            ...additionalData,
          })

          const response = await axios({
            method: 'post',
            url: authApiEndpoint,
            data,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/x-www-form-urlencoded',
              'X-FLUX-CP-Cookie': 'true',
            },
            withCredentials: true,
            timeout: 15 * 1000,
          })
          if (response.status === 200) {
            /* eslint-disable no-underscore-dangle */
            state.baseUrl = process.env.VUE_APP_API_BASE_URL as string
            state.loginName = loginName
            state.password = password
            this.storeSessionData(response)
            isSuccess = true

            SecureKeyStore.setItem('sfgoUserCredential', { loginId: loginName, password })
          }
          apiResponse = new APIResponse(response)
        } catch (e) {
          isSuccess = false
          if (isAxiosError(e)) {
            apiResponse = new APIResponse(e)
          }
          Logger.error('LoginStore#login: Failed to login.', e)
        }
        return {
          isSuccess,
          apiResponse,
        }
      },
      /**
       * API呼び出しが401エラーとなった場合にリフレッシュトークン認証を行う。
       * @return {Promise<string>} アクセストークン
       */
      async refreshAuthLogic(): Promise<string> {
        if (!state.refreshToken) {
          return ''
        }
        // eslint-disable @typescript-eslint/camelcase
        const data = qs.stringify({
          grant_type: 'refresh_token',
          refresh_token: state.refreshToken,
        })

        const response = await axios
          .post(`${state.baseUrl}/${state.orgId}/auth`, data, {
            headers: {
              'X-FLUX-CP-Cookie': 'true',
              'Content-type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
          })
          .catch((error: AxiosError) => error)
        if (isAxiosResponse(response) && response.status === 200) {
          this.storeSessionData(response)
        } else {
          Logger.info(`LoginStore#refreshAuthLogic: Failed to refresh token. response: ${response}`)
          state.sessionExpired = true
        }
        return state.accessToken
      },
      /**
       * 同時ログイン上限数を超えた状態にする
       * @param response APIレスポンス
       */
      concurrentSessionLimitExceeded(response: AxiosResponse) {
        Logger.debug(`LoginStore#concurrentSessionLimitExceeded: response: ${response}`)
        state.sessionLimitExceeded = true
      },
      /**
       * 認証APIレスポンスデータをセッションに格納する。
       * @param response 認証APIのレスポンス
       */
      storeSessionData(response: AxiosResponse) {
        state.displayName = response.data.displayName
        state.loginId = response.data._id
        state.userRoles = response.data.roles
        state.accessToken = response.data.access_token
        state.refreshToken = response.data.refresh_token
        state.orgId = response.data._organization
        state.userId = response.data._id
        state.orgDetail = response.data.organizationDetail
        state.cookies = APIResponse.getCookies(response)
        state.movieCookies = APIResponse.getMovieCookies(response)
        state.fileCookies = APIResponse.getFileCookies(response)
        state.imageCookies = APIResponse.getImageCookies(response)
        state.audiosCookies = APIResponse.getAudiosCookies(response)
      },
      /**
       * ログイン情報をクリアする
       */
      clearLoginInfo() {
        Object.assign(state, initialState)
      },
    }

    // アクセストークン定期更新用のintervalId
    let periodicallyRefreshAccessTokenIntervalId: number | null = null

    /**
     * アクセストークンを定期更新する処理を開始する。
     */
    const startPeriodicallyRefreshAccessToken = () => {
      store.refreshAuthLogic().finally(() => {
        if (periodicallyRefreshAccessTokenIntervalId) {
          clearInterval(periodicallyRefreshAccessTokenIntervalId)
        }

        periodicallyRefreshAccessTokenIntervalId = setInterval(async () => {
          await store.refreshAuthLogic()
          Logger.info('LoginStore: Finish periodically refresh access token.')
        }, 50 * 60 * 1000)
      })
    }

    /**
     * アクセストークンを定期更新する処理を停止する。
     */
    const stopPeriodicallyRefreshAccessToken = () => {
      if (periodicallyRefreshAccessTokenIntervalId) {
        clearInterval(periodicallyRefreshAccessTokenIntervalId)
      }
    }

    return Object.assign(store, {
      startPeriodicallyRefreshAccessToken,
      stopPeriodicallyRefreshAccessToken,
    })
  }
}

const value: ValueType<LoginStore> = {}

export default {
  createStore: new LoginStore().createStore,
  value,
}
