import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * NTPパケット情報の型定義
 */
export type NTPPacket = {
  time: number
}

/**
 * NTPクライアントの機能を提供する。
 *
 * 以下のCordovaプラグインを利用して処理を行う。
 * https://github.com/hub9/cordova-plugin-sntp
 * 現状、NTP時刻取得の処理は Android でのみ機能する。
 */
export default class NTPClient {
  /**
   * NTPクライアント機能が利用可能かどうかを判定する。
   *
   * @return NTPクライアント機能が利用可能な場合、 true を返す
   */
  static available() {
    return DeviceInfo.isAndroid() && !!window.cordova?.plugins?.sntp
  }

  /**
   * NTPクライアントを初期化し、利用可能な状態にする。
   *
   * @param ntpServer NTPサーバーのURL。 未指定の場合、 AWS の公開NTP を利用する
   * @param timeout NTPサーバーへの接続タイムアウト(ミリ秒)
   */
  static init(ntpServer = 'time.aws.com', timeout = 5000) {
    window.cordova.plugins.sntp.setServer(ntpServer, timeout)
  }

  /**
   * NTPサーバーから時刻を取得する。
   *
   * @return NTPサーバーからのレスポンスを待機するためのPromise
   */
  static async getTime(): Promise<number> {
    return new Promise((resolve, reject) => {
      window.cordova.plugins.sntp.getTime(
        (ntpPacket: NTPPacket) => {
          Logger.debug(
            `NTPClient#syncTime: The actual amount of milliseconds since epoch is: ${ntpPacket.time}`,
          )
          resolve(ntpPacket.time)
        },
        (errorMessage: string) => {
          Logger.info(`Failed to get time from ntp. error: ${errorMessage}`)
          reject(errorMessage)
        },
      )
    })
  }
}
