























import { defineComponent, ref, watch } from '@vue/composition-api'
import { uniqBy } from 'lodash'
import I18n from '@/locales/I18n'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useContractPlan from '@/store/hook/useCoupon'
import ContractInfoPlanDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoPlanDocument'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import UserStore from '@/store/stores/pageStore/common/UserStore'

/**
 * 決済: クレジット入力セクション
 */
export default defineComponent({
  name: 'CouponInputSection',
  components: {
    AtomInputText,
    AtomInputButton,
    FormErrorMessageParts,
  },
  setup(_props, context) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { inputtedCouponCode, fetchTargetCoupon, targetCoupon, clearCoupons } = signupPageStore
    const { fetchTargetCouponByCouponId, coupons } = useContractPlan()

    const couponError = ref<boolean>(false)
    const couponErrorMessage = ref<string>('')
    /** 取得したクーポン情報 */
    const targetCouponItem = ref<CouponDocument | undefined>(undefined)

    /**
     * 紹介コードの利用歴チェック関数
     * @param contractInfoPlans
     */
    const isReferralCodeUsed = async (contractInfoPlans: ContractInfoPlanDocument[]) => {
      const promise: Array<Promise<Response<CouponDocument>>> = []

      const filteredContractInfoPlans = uniqBy(contractInfoPlans, 'couponId')

      filteredContractInfoPlans.forEach((plan: ContractInfoPlanDocument) => {
        if (!plan.couponId) return
        promise.push(fetchTargetCouponByCouponId(plan.couponId))
      })

      await Promise.all(promise)
      if (coupons.value.length === 0) return false

      return coupons.value.some((coupon) => coupon.isReferralCode)
    }

    /**
     * クーポンチェック関数
     * @param inputtedValue
     */
    const validateCouponCode = async (inputtedValue: string) => {
      const contractInfoPlans = signupPageStore.contractInfoPlans.value

      // 入力値がブランクの場合、エラーを返す
      if (inputtedValue === '') {
        couponError.value = true
        couponErrorMessage.value = I18n.tc('formParts.errorMessage.couponBlank')
        return
      }

      targetCouponItem.value = targetCoupon(inputtedValue)

      // 紹介コードを入力し、紹介コードの使用履歴がある場合、エラーを返す
      const isReferralCodeInputted = targetCouponItem.value?.isReferralCode
      if (isReferralCodeInputted && (await isReferralCodeUsed(contractInfoPlans))) {
        couponError.value = true
        couponErrorMessage.value = I18n.tc('formParts.errorMessage.referralCodeAlreadyUsed')
        return
      }

      // クーポンが見つからなかった場合、エラーを返す
      if (!targetCouponItem.value) {
        couponError.value = true
        couponErrorMessage.value = I18n.tc('formParts.errorMessage.couponError')
        return
      }

      // 自身の紹介コードを入力していた場合、エラーを返す
      if (
        targetCouponItem.value &&
        targetCouponItem.value.distributorUserId === UserStore.value.user.value.id
      ) {
        couponError.value = true
        couponErrorMessage.value = I18n.tc('formParts.errorMessage.inputOwnCouponError')
        return
      }

      // すでに使用済みのクーポンの場合、エラーを返す
      if (
        targetCouponItem.value &&
        contractInfoPlans.find((v) => v.couponId === targetCouponItem.value?.couponId)
      ) {
        couponError.value = true
        couponErrorMessage.value = I18n.tc('formParts.errorMessage.couponAlreadyUsed')
        return
      }

      couponError.value = false
    }

    /**
     * クーポンコードの入力値が変化したタイミングでバリデーションエラーを消す
     */
    watch(
      () => inputtedCouponCode.value,
      () => {
        couponError.value = false
      },
    )

    /**
     * クーポン登録ボタン押下時処理
     */
    const handlerSubmit = async () => {
      clearCoupons()
      if (inputtedCouponCode.value) {
        /** 入力したクーポンコードを取得 */
        const result = await fetchTargetCoupon(inputtedCouponCode.value)
        if (!result.isSuccess && result.response?.isNetworkError) {
          // ネットワークエラーでクーポンを取得できなかった場合は、エラーメッセージを表示する
          await MessageDialogStore.value.open?.({
            errorApiResponse: result.response,
            isVisibleCancelButton: false,
          })
        }
      }

      /** バリデーションチェックを実行 */
      await validateCouponCode(inputtedCouponCode.value)
      // エラーがある場合、プランリストにクーポンを表示しない
      if (couponError.value) {
        context.emit('handlerSubmit', null)
        return
      }

      context.emit('handlerSubmit', targetCouponItem.value)
    }

    return {
      inputtedCouponCode,
      couponError,
      couponErrorMessage,
      handlerSubmit,
    }
  },
})
