



















































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { CircuitGpsPosition } from '@/components/RaceVideoPage/RaceVideoPane/CircuitMapSection.vue'

/**
 * レース動画再生画面 GPSマップ 車両SVG コンポーネント
 */
export default defineComponent({
  name: 'CircuitMapCarParts',
  props: {
    /**
     * 車両番号
     */
    number: {
      type: Number,
      required: true,
      default: 0,
    },
    /**
     * サーキット緯度経度情報
     */
    circuitGpsPosition: {
      type: Object as PropType<CircuitGpsPosition>,
      required: true,
    },
    /**
     * 車両位置情報 緯度
     */
    gpsLat: {
      type: Number,
      default: 0,
    },
    /**
     * 車両位置情報 緯度
     */
    gpsLng: {
      type: Number,
      default: 0,
    },
    /**
     * 車両位置情報 取得時間
     */
    createdDate: {
      type: Number,
      default: 0,
    },
    /**
     * PIT IN フラグ
     */
    pitInWorking: {
      type: Boolean,
      default: false,
    },
    /**
     * オーバーテイク残時間
     */
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    /**
     * OTS有効時 フラグ
     */
    otsEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 選択選手フラグ
     */
    selectedPlayer: {
      type: Boolean,
      default: false,
    },
    /**
     * 車両データアニメーションフラグ
     */
    animateGpsMapCar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // サーキットサイズ取得
    const mapWidthValue = props.circuitGpsPosition.end.lng - props.circuitGpsPosition.root.lng
    const mapHeightValue = props.circuitGpsPosition.end.lat - props.circuitGpsPosition.root.lat

    const tempPosition = ref({
      lat: props.circuitGpsPosition.root.lng,
      lng: props.circuitGpsPosition.root.lat,
      top: '',
      left: '',
      createdDate: 0,
    })
    const tempThrottlingPosition = ref({
      lat: props.circuitGpsPosition.root.lng,
      lng: props.circuitGpsPosition.root.lat,
      createdDate: 0,
    })

    // スロットリング設定
    const THROTTLING_THRESHOLD = 4
    const NO_ANIMATION_GAP_TIME_MS = 10000 // データ反映時間の差が XX ms 以上だった場合に移動アニメーションを適用しない
    const CAR_DISABLED_THRESHOLD = 5 // データ反映時 データがX度連続して空だったら disabled表示 にするか (50ms * 8 * 5 = 2秒断続)
    const CAR_HIDE_THRESHOLD = 50 // データ反映時 データがX度連続して空だったら 非表示 にするか (50ms * 8 * 50 = 20秒断続)
    let throttlingCount = THROTTLING_THRESHOLD

    const blankCount = ref(0)
    const enableAnimation = ref(true)
    const carDisabled = ref(false)
    const carVisible = ref(true)
    const carMounted = ref({
      mount: false,
      count: 0,
      moveCount: 0,
    })

    /**
     * 軽度緯度位置指定 ポジション変換
     * @param gpsLat: 緯度
     * @param gpsLng: 経度
     * @param createdDate: GPS取得時間
     * @param animate: アニメーション有効化フラグ
     */
    const convertGpsPosition = (
      gpsLat: number,
      gpsLng: number,
      createdDate: number,
      animate: boolean,
    ) => {
      const gpsBlank = gpsLat === 0 || gpsLng === 0
      const gpsMoved =
        tempPosition.value.lat !== (gpsLat || tempThrottlingPosition.value.lat) ||
        tempPosition.value.lng !== (gpsLng || tempThrottlingPosition.value.lng)
      const createDateDiff = Math.abs(createdDate - tempThrottlingPosition.value.createdDate)

      if (!animate || createDateDiff >= NO_ANIMATION_GAP_TIME_MS) {
        // アニメーションOFF & 初期化
        enableAnimation.value = false
        carMounted.value.moveCount = 0
        throttlingCount = 0
      }

      if (throttlingCount % THROTTLING_THRESHOLD !== 0) {
        // 断続的にデータが得られなかった際、スロットリング中にデータが1回でも取得した場合に反映できるように保持
        tempThrottlingPosition.value.lat = !gpsBlank ? gpsLat : tempThrottlingPosition.value.lat
        tempThrottlingPosition.value.lng = !gpsBlank ? gpsLng : tempThrottlingPosition.value.lng
        tempThrottlingPosition.value.createdDate = gpsMoved
          ? createdDate
          : tempThrottlingPosition.value.createdDate

        // 空カウント等
        blankCount.value = !gpsBlank ? 0 : blankCount.value

        throttlingCount += 1
        return {
          display: carVisible.value ? 'initial' : 'none',
          top: carMounted.value.mount ? tempPosition.value.top : null,
          left: carMounted.value.mount ? tempPosition.value.left : null,
        }
      }

      // 移動判定: アニメーション有効化に使用
      carMounted.value.moveCount += gpsMoved ? 1 : 0

      // 位置情報を計算
      tempPosition.value = {
        createdDate: gpsMoved ? createdDate : tempThrottlingPosition.value.createdDate,
        lat: gpsLat || tempThrottlingPosition.value.lat,
        lng: gpsLng || tempThrottlingPosition.value.lng,
        top: `${
          (((!gpsBlank ? gpsLat : tempThrottlingPosition.value.lat) -
            props.circuitGpsPosition.root.lat) /
            mapHeightValue) *
          100
        }%`,
        left: `${
          (((!gpsBlank ? gpsLng : tempThrottlingPosition.value.lng) -
            props.circuitGpsPosition.root.lng) /
            mapWidthValue) *
          100
        }%`,
      }

      // 初期にデータが空の場合は表示しないように判定
      carMounted.value.count += (gpsLat || tempPosition.value.lat) > 0 ? 1 : 0
      carMounted.value.mount = carMounted.value.count > 1

      // データ空チェック
      blankCount.value = gpsBlank ? Number(blankCount.value) + 1 : 0

      // disabled表示判定 (半透明化)
      carDisabled.value = blankCount.value > CAR_DISABLED_THRESHOLD

      // アニメーション有効化判定 リセット処理
      carMounted.value.moveCount = carDisabled.value ? 0 : carMounted.value.moveCount

      // アニメーション有効化判定
      enableAnimation.value =
        carMounted.value.moveCount > 1 && createDateDiff < NO_ANIMATION_GAP_TIME_MS

      // 表示判定
      carVisible.value = blankCount.value < CAR_HIDE_THRESHOLD && carMounted.value.mount

      // 一時保持用に格納
      tempThrottlingPosition.value = {
        lat: gpsLat || tempPosition.value.lat,
        lng: gpsLng || tempPosition.value.lng,
        createdDate: gpsMoved ? createdDate : tempPosition.value.createdDate,
      }

      throttlingCount += 1
      return {
        display: carVisible.value ? 'initial' : 'none',
        top: carMounted.value.mount ? tempPosition.value.top : null,
        left: carMounted.value.mount ? tempPosition.value.left : null,
      }
    }

    const carPosition = computed(() =>
      convertGpsPosition(props.gpsLat, props.gpsLng, props.createdDate, props.animateGpsMapCar),
    )

    return {
      enableAnimation,
      carDisabled,
      carPosition,
      carMounted,
    }
  },
})
