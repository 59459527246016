














import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  PropType,
  toRefs,
  watch,
} from '@vue/composition-api'
import { throttle } from 'lodash'
import useVideoPlayer from '@/components/hook/useVideoPlayer'
import StoreUtil from '@/store/StoreUtil'
import { EditHighlightData } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'
import { VideoPlayerViewClass } from '@/util/videoplayer/VideoPlayerViewType'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'

/**
 * レース動画再生画面、動画プレーヤーセクション
 */
export default defineComponent({
  name: 'HighlightPreviewVideoPlayerSection',
  props: {
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * ハイライトの作成・編集で使用するデータ
     */
    highlightData: {
      type: Object as PropType<EditHighlightData>,
      required: true,
    },
  },
  setup(props) {
    const isPc = DeviceInfo.isForPC()
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { playRadioDataAudio } = useRadio()

    const {
      highlightStore,
      englishLiveAngleId,
      computeCurrentTime,
      contentsInfo,
      angleMovieInfos,
      getRadioDataSetForHighlight,
      computeActualTimeForVideoPlaybackPosition,
      radioDataStore,
    } = raceVideoPageStore

    // プレビュー動画がアングル動画かどうか
    const isAngle = !!props.highlightData.editHighlightData.angleMovieId

    // ハイライトの動画開始位置
    let highlightStartTime = props.highlightData.editHighlightData.startTime || 0

    let previewMovieInfo: ContentsInfoDocument | AngleMovieInfoDocument = contentsInfo.value

    if (isAngle) {
      const angleMovieInfo = angleMovieInfos.value.find(
        (angleMovie) =>
          angleMovie.angleMovieId === props.highlightData.editHighlightData.angleMovieId,
      )
      // アングル動画の場合に、ハイライトの動画開始位置を中継映像から算出する
      if (angleMovieInfo) {
        previewMovieInfo = angleMovieInfo
        highlightStartTime = computeCurrentTime(
          previewMovieInfo.angleId || '',
          contentsInfo.value,
          props.highlightData.editHighlightData.startTime,
          false,
        )
      }
    }

    // ハイライトプレビュー動画の音声を設定する
    let volume
    if (isPc) {
      if (raceVideoPageStore.raceVideoPageState.volume.currentVolume === 0) {
        volume = 0
      } else {
        volume = raceVideoPageStore.raceVideoPageState.volume.currentVolume / 100
      }
    } else {
      volume = undefined
    }

    const loginStore = StoreUtil.useStore('LoginStore')

    // 動画プレーヤーを初期化
    const {
      videoPlayer: videoPlayerForUseVideoPlayer,
      videoStatus: videoStatusForUseVideoPlayer,
      videoPlayerStatus: videoPlayerStatusForUseVideoPlayer,
      resizeVideoPlayer,
    } = useVideoPlayer({
      id: 'sfgo-highlight-preview-video',
      target: 1,
      movieUrl: previewMovieInfo.playlistUrl,
      live: previewMovieInfo.isLiveBroadcasting(),
      playerType: appConfigStore.useVideoPlayerType.value,
      viewType: VideoPlayerViewClass.HighlightPreview,
      refreshToken: loginStore.refreshToken,
      cookies: loginStore.movieCookies,
      currentTime: highlightStartTime,
      readVideoTrackTime: false,
      timeDeviations: appConfigStore.getTimeDeviation(),
      movieLength: previewMovieInfo.movieLength || undefined,
      seekToEnd: false,
      startTime: highlightStartTime,
      endTime:
        highlightStartTime +
        props.highlightData.editHighlightData.endTime -
        props.highlightData.editHighlightData.startTime,
      zPositionForNative: 1,
      volume,
    })

    highlightStore.setVideoPlayer(videoPlayerForUseVideoPlayer)
    highlightStore.setVideoStatus(videoStatusForUseVideoPlayer)
    highlightStore.setVideoPlayerStatus(videoPlayerStatusForUseVideoPlayer)

    const { videoPlayer, videoStatus, videoPlayerStatus } = toRefs(
      highlightStore.raceVideoPageHighlightState,
    )

    // プレビュー動画がオンボード映像かどうか
    const isOnboard = isAngle && previewMovieInfo.angleId !== englishLiveAngleId.value

    // オンボード映像なら、無線データ取得に利用する実時間を算出
    const highlightStartActualTime = isOnboard
      ? computeActualTimeForVideoPlaybackPosition(false, 100, {
          targetVideoTime: highlightStartTime,
          targetVideoTrackDateTime: props.highlightData.editHighlightData.videoTrackDateTime,
          targetMovieInfo: previewMovieInfo as AngleMovieInfoDocument,
        })
      : 0

    /**
     * ハイライトプレビューの時間の範囲内で視聴する無線データを取得する
     */
    const radioDataSet = computed(() => {
      if (isOnboard) {
        return getRadioDataSetForHighlight(
          previewMovieInfo as AngleMovieInfoDocument,
          highlightStartActualTime,
        )
      }
      return []
    })

    /**
     * 動画プレイヤーの再生が終了しているかどうかを監視する
     * 終了していたら、動画の開始位置に再生位置を戻す
     */
    watch(
      () => videoPlayerStatus.value?.playFinished,
      async (value) => {
        if (value) {
          // 動画の開始位置にシークする
          await videoPlayer.value?.setCurrentTime(videoStatus.value?.startTime || 0)
          radioDataStore.pauseRadioAudio()
          videoPlayer.value?.play()
        }
      },
    )

    /**
     * 動画プレイヤーの初期化が完了しているかどうかを監視する
     * 初期化が完了したら動画を再生する。また、無線が再生中なら停止する。
     */
    watch(
      () => videoPlayer.value?.initialized(),
      (value) => {
        if (value) {
          videoPlayer.value?.play()
          radioDataStore.pauseRadioAudio()
        }
      },
    )

    /**
     * 動画プレイヤーの再生位置を監視する
     * 無線を再生するかどうかを判断し無線の再生位置に動画の再生位置がきたら再生する
     */
    if (isAngle) {
      watch(
        () => videoStatus.value?.currentTime,
        throttle(async (value) => {
          if (
            !radioDataStore.livePlayerRadioEnabled.value ||
            radioDataStore.isPlayingRadio.value ||
            value === undefined
          ) {
            return
          }

          // ハイライト開始位置からの経過時間（秒）
          const elapsedTimeHighlightStarted = (value - highlightStartTime) * 1000
          // 動画の再生位置の実時間
          const actualTime = elapsedTimeHighlightStarted + highlightStartActualTime

          // 動画の再生位置が無線の再生位置かを判断する
          const currentPlayerRadioData = radioDataSet.value.find(
            (radio) =>
              actualTime >= radio.clip_start_time && radio.clip_start_time + 1000 >= actualTime,
          )

          if (currentPlayerRadioData) {
            await playRadioDataAudio(currentPlayerRadioData, false)
            Logger.debug(
              `watch: Play radio audio for highlight preview. data: ${currentPlayerRadioData}`,
            )
          }
        }, 500),
      )

      /**
       * 無線の再生状態を監視し、無線再生中は動画プレーヤーを無音にする。
       */
      watch(
        () => raceVideoPageStore.radioDataStore.isPlayingRadio.value,
        () => {
          if (!isPc)
            videoPlayer.value?.muted(raceVideoPageStore.radioDataStore.isPlayingRadio.value)
        },
      )
    }

    /**
     * ハイライトプレビュー動画プレイヤーをリサイズする
     */
    const resizePreviewVideoPlayer = () => {
      setTimeout(() => {
        resizeVideoPlayer()
      }, 100)
    }

    onMounted(() => {
      // キーボード表示イベント時に動画プレイヤーをリサイズする
      window.addEventListener('keyboardDidShow', resizePreviewVideoPlayer)
      // キーボード非表示イベント時に動画プレイヤーをリサイズする
      window.addEventListener('keyboardDidHide', resizePreviewVideoPlayer)
    })
    onBeforeUnmount(() => {
      // アンマウント時に、マウント時に設定したイベントを解除する
      window.removeEventListener('keyboardDidShow', resizePreviewVideoPlayer)
      window.removeEventListener('keyboardDidHide', resizePreviewVideoPlayer)
    })

    return {
      videoPlayerStatus,
    }
  },
})
