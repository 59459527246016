import { computed, toRefs, watch } from '@vue/composition-api'
import { now, throttle } from 'lodash'
import StoreUtil from '@/store/StoreUtil'
import GpsDocument, { GpsDataType } from '@/store/stores/collectionModule/documents/gps/GpsDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import Const from '@/util/Const'
import useRanking from '@/components/RaceVideoPage/hook/useRanking'
import PlayerLiveTiming, {
  PlayerLiveTimingDataType,
} from '@/store/stores/collectionModule/documents/liveTiming/PlayerLiveTiming'

/**
 * 車両のGPS位置情報
 */
export type CarGpsPositionDataType = {
  id: string | null // 選手ID
  carNo: number // 車体番号
  lat: number // GPS緯度
  lng: number // GPS経度
  pitInWorking: boolean // PITINルールクリアフラグ
  ots: number // オーバーテイク残時間
  otsEnabled: boolean // オーバーテイク有効化
  createdDate: number // 作成日時
  shortName: string // ドライバーの省略名(NOJ など)
  rank: number // 順位
}

/**
 * 空のGPS位置情報。動画再生位置に対応するGPS情報がなかった場合に設定する。
 */
export const emptyMapPositionData = {
  id: '',
  carNo: 0,
  lat: 0,
  lng: 0,
  pitInWorking: false,
  ots: 0,
  otsEnabled: false,
  createdDate: new Date().getTime(),
  shortName: '---',
  rank: 0,
} as CarGpsPositionDataType

/**
 * 車両毎のGPS位置情報の型。
 * 車両番号をキーにして、その選手のGPS位置情報を値として保持する。
 */
export type CarGpsPositionDataMap = Record<string, CarGpsPositionDataType>

/**
 * GPS画面で参照する必要のあるデータの取得処理を提供する。
 * @param watchVideoStatus 動画再生位置の変化をwatchするかどうか。
 * 動画再生に応じてGPSデータの取得を行う場合に true を指定する。
 */
export default function useGps(watchVideoStatus = true) {
  const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

  const { getCurrentPlayerRankingDataList, getCurrentPlayerTelemetry } = useRanking()

  const {
    computeActualTimeForVideoPlaybackPosition,
    participatingPlayers,
    targetRace,
    targetChampionship,
    gpsStore,
  } = raceVideoPageStore

  const { videoStatus, preloadFetchingRange } = toRefs(raceVideoPageStore.raceVideoPageState)

  // GPSデータ
  const { fetchGps, isGpsFetched, clearGps } = gpsStore

  // 最後にGPSデータのフェッチを行なった日時
  let lastPlayerGpsFetchTime = 0
  // GPSデータのフェッチを行なっているかどうか
  let playerGpsFetchingNow = false
  // データを保持する最小期間。メモリに保持時ているデータがこの期間よりも短くなった場合に、データフェッチを行う
  const minimumDataRetentionPeriods = 3 * 1000

  if (watchVideoStatus) {
    watch(
      videoStatus,
      throttle(async () => {
        if (!targetRace.value) {
          return
        }

        // GPSデータの現在の動画の再生時間の実時間を取得する。
        const gpsFrom = computeActualTimeForVideoPlaybackPosition(true, 50)

        // 現在の動画の再生位置のGPSデータを保持しているかチェックし、保持していない場合、APIを呼び出して取得する。
        if (
          !isGpsFetched(gpsFrom + minimumDataRetentionPeriods) &&
          now() - lastPlayerGpsFetchTime > 2000 &&
          !playerGpsFetchingNow
        ) {
          // 取得開始位置から、preloadFetchingRange で指定された範囲までの範囲を取得する
          const to = gpsFrom + preloadFetchingRange.value
          // 再生位置から3秒先のGPSデータを保持していない場合、取得を開始
          // ただし、ライブ配信時など、まだ、先の時間帯にGPSデータがそもそも存在しない場合もあるため、取得は2秒に一度に限定する
          lastPlayerGpsFetchTime = now()
          playerGpsFetchingNow = true
          await fetchGps(targetRace.value, gpsFrom, to).finally(() => {
            playerGpsFetchingNow = false
          })
        }
      }, 500),
      { deep: true },
    )
  }

  /**
   * サーキットマップPathデータ
   */
  const imgPath = computed(() => {
    let circuitImgPath
    const circuit = targetChampionship.value.course
    switch (circuit) {
      case 'FUJI_SPEEDWAY':
        circuitImgPath = Const.GPS_CIRCUIT_MAP_PATH.FUJI_SPEEDWAY
        break
      case 'SUZUKA_CIRCUIT':
        circuitImgPath = Const.GPS_CIRCUIT_MAP_PATH.SUZUKA_CIRCUIT
        break
      case 'AUTOPOLIS':
        circuitImgPath = Const.GPS_CIRCUIT_MAP_PATH.AUTOPOLIS
        break
      case 'SPORTSLAND_SUGO':
        circuitImgPath = Const.GPS_CIRCUIT_MAP_PATH.SPORTSLAND_SUGO
        break
      case 'TWIN_RING_MOTEGI':
        circuitImgPath = Const.GPS_CIRCUIT_MAP_PATH.TWIN_RING_MOTEGI
        break
      default:
        circuitImgPath = targetChampionship.value.course
        break
    }
    return circuitImgPath
  })

  /**
   * サーキットマップデータ
   */
  const gpsMap = computed(() => {
    let circuitGpsMap
    const circuit = targetChampionship.value.course
    switch (circuit) {
      case 'FUJI_SPEEDWAY':
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.FUJI_SPEEDWAY
        break
      case 'SUZUKA_CIRCUIT':
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.SUZUKA_CIRCUIT
        break
      case 'AUTOPOLIS':
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.AUTOPOLIS
        break
      case 'SPORTSLAND_SUGO':
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.SPORTSLAND_SUGO
        break
      case 'TWIN_RING_MOTEGI':
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.TWIN_RING_MOTEGI
        break
      default:
        circuitGpsMap = Const.GPS_CIRCUIT_MAP_GPS.FUJI_SPEEDWAY
        break
    }
    return circuitGpsMap
  })

  // 直前に表示していたGPSデータ。車両番号をキーにして値を保持する
  const previousPlayerGpsData = {} as Record<string, GpsDataType>
  // 直前に表示していたライブタイミングデータ。車両番号をキーにして値を保持する
  const previousPlayerLiveTimingData = {} as Record<string, PlayerLiveTimingDataType>

  /**
   * 現在の動画の再生位置に対応する各選手のGPSデータを作成する。
   */
  const getCurrentPlayerGps = computed((): CarGpsPositionDataMap => {
    // 現在の動画の再生位置のGPSデータを取得する
    const actualTimeRounded = computeActualTimeForVideoPlaybackPosition(true, 50)

    // 車両番号をキーにして、その選手のGPSデータを値に設定したマップオブジェクトを生成する
    return participatingPlayers.value.reduce<CarGpsPositionDataMap>(
      (map: CarGpsPositionDataMap, player: PlayerDocument) => {
        const emptyGpsData = GpsDocument.EMPTY_GPS_DATA
        emptyGpsData.carNo = player.getDisplayCarNo()
        emptyGpsData.createdDate = actualTimeRounded

        // 対象選手のライブタイミングデータを取得
        let currentPlayerLiveTimingData = getCurrentPlayerRankingDataList.value.find(
          (playerLiveLimingData) => playerLiveLimingData.carNo === player.getDisplayCarNo(),
        )
        if (!currentPlayerLiveTimingData) {
          // 現在の動画再生位置に対応するライブタイミングデータがない場合、前回表示していたライブタイミングデータを表示する
          const emptyPlayerLiveTiming = PlayerLiveTiming.createEmptyData(player)
          if (!previousPlayerLiveTimingData[player.getDisplayCarNo()]) {
            previousPlayerLiveTimingData[player.getDisplayCarNo()] = emptyPlayerLiveTiming
          }
          currentPlayerLiveTimingData = previousPlayerLiveTimingData[player.getDisplayCarNo()]
          if (Math.abs(currentPlayerLiveTimingData.createdDate - actualTimeRounded) > 5000) {
            currentPlayerLiveTimingData = emptyPlayerLiveTiming
          }
        }

        // 対象車両のGPSデータ取得
        const playerGpsData = gpsStore.getPlayerGps(String(player.squadNumber), actualTimeRounded)
        let currentGpsData = playerGpsData?.gpsData
        if (!currentGpsData) {
          if (!previousPlayerGpsData[player.getDisplayCarNo()]) {
            previousPlayerGpsData[player.getDisplayCarNo()] = emptyGpsData
          }

          // 現在の動画再生位置に対応するGPSデータがない場合、前回表示していたGPSデータを表示する
          currentGpsData = previousPlayerGpsData[player.getDisplayCarNo()]
          if (Math.abs(currentGpsData.createdDate - actualTimeRounded) > 5000) {
            // 現在の再生位置よりも5秒以上の差がある場合、破棄する
            currentGpsData = emptyGpsData
          }
        }

        // 現在の再生位置の対象の車両のテレメトリーデータを取得
        const currentTelemetryData = getCurrentPlayerTelemetry.value[player.getDisplayCarNo()]

        // ライブタイミングデータ、GPS情報を組み合わせて、GPS位置情報を生成する。
        const gpsMapData: CarGpsPositionDataType = {
          id: player.playerId,
          carNo: Number(player.getDisplayCarNo()),
          lat: currentGpsData.gps_lat,
          lng: currentGpsData.gps_lng,
          pitInWorking: currentPlayerLiveTimingData.pitInWorking,
          ots: currentTelemetryData?.ots ?? 0,
          otsEnabled: currentTelemetryData?.otsEnabled ?? false,
          createdDate: new Date().getTime(),
          shortName: player.playerShortName.en ?? '',
          rank: Number(currentPlayerLiveTimingData.position),
        }

        const hashmap = map
        hashmap[player.getDisplayCarNo()] = gpsMapData

        previousPlayerGpsData[player.getDisplayCarNo()] = currentGpsData
        previousPlayerLiveTimingData[player.getDisplayCarNo()] = currentPlayerLiveTimingData
        return hashmap
      },
      {},
    )
  })

  /**
   * 保持しているGPSデータをクリアする。
   */
  const clearGpsData = () => {
    clearGps()
  }

  return {
    imgPath,
    gpsMap,
    getCurrentPlayerGps,
    getCurrentPlayerRankingDataList,
    clearGpsData,
  }
}
