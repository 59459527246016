/* eslint-disable class-methods-use-this */
import { computed, reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import { VideoPlayerType } from '@/util/videoplayer/VideoPlayerType'
import Env from '@/util/Env'

/**
 * SFgoアプリの設定情報を保持するためのストア。
 */
class AppConfigStore implements StoreBase {
  createStore() {
    const appConfigState = reactive({
      /**
       * 現在のサーキットモードの設定
       */
      circuitMode: false,

      /**
       * SFgoが動作しているデバイスの時刻のずれ(ミリ秒)
       */
      timeDeviation: 0,
    })

    /**
     * 現在のサーキットモードの設定を取得/設定するための算出プロパティ
     */
    const currentCircuitMode = computed({
      get: (): boolean => appConfigState.circuitMode,
      set: (circuitMode: boolean) => {
        appConfigState.circuitMode = circuitMode
      },
    })

    /**
     * SFgoが動作しているデバイスの時刻のずれを設定する。
     */
    const setTimeDeviation = (diff: number) => {
      appConfigState.timeDeviation = diff
    }

    /**
     * SFgoが動作しているデバイスの時刻のずれを取得する。
     */
    const getTimeDeviation = () => appConfigState.timeDeviation

    /**
     * 現在のアプリの設定で利用するべき動画プレーヤーのタイプを判定する。
     * - サーキットモードの場合、AirVideoPlayerを返す
     * - モックの場合、ブラウザ版動画プレーヤーを示す Browser を返す。
     * それ以外の場合、undefined を返す。
     */
    const useVideoPlayerType = computed(() => {
      let playerType: VideoPlayerType | undefined
      if (Env.isMock) {
        playerType = 'Browser'
      } else if (currentCircuitMode.value) {
        playerType = 'AirVideoPlayer'
      }
      return playerType
    })

    return {
      currentCircuitMode,
      useVideoPlayerType,
      setTimeDeviation,
      getTimeDeviation,
    }
  }
}

const value: ValueType<AppConfigStore> = {}

export default {
  createStore: new AppConfigStore().createStore,
  value: value as Required<typeof value>,
}
