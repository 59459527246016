





































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import SeekBarParts from '@/components/RaceVideoPage/RaceVideoPane/parts/SeekBarParts.vue'
import LiveIconParts from '@/components/RaceVideoPage/RaceVideoPane/parts/LiveIconParts.vue'
import ViewAngleToggleSwitcherParts from '@/components/RaceVideoPage/RaceVideoPane/parts/ViewAngleToggleSwitcherParts.vue'
import ToggleButtonParts from '@/components/RaceVideoPage/RaceVideoPane/parts/ToggleButtonParts.vue'
import VolumeControllerParts from '@/components/RaceVideoPage/RaceVideoPane/parts/VolumeControllerParts.vue'
import { VideoStatusType } from '@/components/hook/useVideoPlayer'
import { ViewAngleType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * レース動画再生画面 動画プレーヤー オーバーレイコンポーネント
 */
export default defineComponent({
  name: 'OverlayControllerParts',
  components: {
    SeekBarParts,
    LiveIconParts,
    ViewAngleToggleSwitcherParts,
    ToggleButtonParts,
    VolumeControllerParts,
  },
  props: {
    /**
     * シークバー用再生時間データ
     */
    videoTimes: {
      type: Object as PropType<VideoStatusType>,
      required: false,
    },
    /**
     * LIVE中継フラグ
     */
    liveEnabled: {
      type: Boolean,
      required: true,
    },
    /**
     * ライブ配信が未配信かどうか
     */
    isLiveNotStarted: {
      type: Boolean,
      required: true,
    },
    /**
     * 表示アングルデータ
     */
    viewAngle: {
      type: String,
      required: true,
    },
    /**
     * 表示コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
    /**
     * ビデオフルスケールフラグ
     */
    videoFullScale: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * ビデオ一時停止フラグ
     */
    videoPaused: {
      type: Boolean,
      default: false,
    },
    /**
     * ビデオスロー再生フラグ
     */
    videoSlow: {
      type: Boolean,
      default: false,
    },
    /**
     * レース情報を取得済みかどうか
     */
    isFetchedRaceInfo: {
      type: Boolean,
      default: false,
    },
    /**
     * 公式映像/オンボード映像の切り替えが可能かどうか
     */
    isAngleChangeAvailable: {
      type: Boolean,
      default: false,
    },
    /**
     * MAP表示フラグ
     */
    mapViewEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 音量調整機能
     */
    hasVolumeControl: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isPc = DeviceInfo.isForPC()
    const videoTimesData = computed({
      get: () => props.videoTimes,
      set: (val) => context.emit('update:videoTimes', val),
    })
    const viewAngleStatus = computed(() => props.viewAngle)
    const screenLandScape = computed(() => props.screenOrientationType.indexOf('landscape') !== -1)
    const viewContentsMode = computed(() => props.viewMode)
    const videoPausedStatus = computed(() => props.videoPaused)
    const videoSlowStatus = computed(() => props.videoSlow)
    const hasRaceInfo = computed(() => props.isFetchedRaceInfo)
    const canAngleChange = computed(() => props.isAngleChangeAvailable)
    const videoFullScaleStatus = computed(() => props.videoFullScale)

    return {
      isPc,
      videoTimesData,
      viewAngleStatus,
      screenLandScape,
      viewContentsMode,
      videoPausedStatus,
      videoSlowStatus,
      hasRaceInfo,
      canAngleChange,
      videoFullScaleStatus,
    }
  },
  methods: {
    /**
     * 表示アングル変更
     * @param angle: 'race' or 'player'
     */
    toggleViewAngle(angle: ViewAngleType): void {
      this.$emit('viwAngleChange', angle)
    },
    /**
     * 簡易マップ表示変更
     */
    toggleMapMode(): void {
      this.$emit('viewMapMode')
    },

    /**
     * 表示モード変更
     * @param mode
     */
    changeViewMode(mode: string): void {
      let rt = ''
      const orgMode = this.viewContentsMode
      if (this.screenLandScape) {
        rt = mode !== this.viewContentsMode ? mode : 'single'
      } else if (mode === 'highlight') {
        rt = this.viewContentsMode === 'highlight' ? 'telemetry' : 'highlight'
      } else if (mode === 'telemetry') {
        rt = this.viewContentsMode === 'telemetry' ? 'highlight' : 'telemetry'
      }
      this.$emit('viewModeChange', rt, orgMode)
    },

    /**
     * 全画面表示変更
     */
    changeFullscreen(): void {
      this.$emit('fullscreenChange')
    },
    /**
     * VIDEOフルスケール表示切り替え
     */
    changeFullScaleVideo(): void {
      this.$emit('fullScaleVideo')
    },

    /**
     * ビデオ 再生 処理
     */
    playVideo(): void {
      this.$emit('videoPlayPauseChange', false)
    },

    /**
     * ビデオ 一時停止 処理
     */
    pauseVideo(): void {
      this.$emit('videoPlayPauseChange', true)
    },

    /**
     * ビデオ 5秒巻き戻し 処理
     */
    prevVideo5s(): void {
      this.$emit('videoPlayChangeCurrentTime', -5.0)
    },

    /**
     * ビデオ 30秒巻き戻し 処理
     */
    prevVideo30s(): void {
      this.$emit('videoPlayChangeCurrentTime', -30.0)
    },

    /**
     * ビデオ 5秒先送り 処理
     */
    nextVideo5s(): void {
      this.$emit('videoPlayChangeCurrentTime', 5.0)
    },

    /**
     * ビデオ 30秒先送り 処理
     */
    nextVideo30s(): void {
      this.$emit('videoPlayChangeCurrentTime', 30.0)
    },

    /**
     * ビデオ スロー再生 処理
     */
    slowVideo(): void {
      this.$emit('videoSlowChange')
    },
    /**
     * LIVEボタン 処理
     */
    onLiveClicked(): void {
      this.$emit('onLiveClicked')
    },
    /**
     * シークバー touchStart時イベント通知
     */
    handleTouchStartSeekBar(): void {
      this.$emit('handleTouchStartSeekBar')
    },
    /**
     * シークバー touchEnd時イベント通知
     */
    handleTouchEndSeekBar(): void {
      this.$emit('handleTouchEndSeekBar')
    },
    /**
     * 音声シークバー イベント通知
     * @param volumeLevel 音量
     */
    handleTouchEndVolumeSeekBar(volumeLevel: number) {
      this.$emit('handleTouchEndVolumeSeekBar', volumeLevel)
    },
    /**
     * 音声ミュート イベント通知
     * @param isMute ミュートしているか
     */
    handleVolumeMute(isMute: boolean): void {
      this.$emit('handleVolumeMute', isMute)
    },
  },
})
