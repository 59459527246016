var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-ticket-list-pane",
      class: {
        "mypage-ticket-list-pane--open-filter": _vm.state.selectingFilterType,
      },
    },
    [
      _c("div", { staticClass: "mypage-ticket-list-pane__information" }, [
        _vm.productHistoriesUpdatedDateTime
          ? _c(
              "p",
              { staticClass: "mypage-ticket-list-pane__information-date" },
              [_vm._v(" " + _vm._s(_vm.productHistoriesUpdatedDateTime) + " ")]
            )
          : _vm._e(),
        _c(
          "p",
          { staticClass: "mypage-ticket-list-pane__information-message" },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc(
                    "MypagePage.MypageTicketListPage.informationMessage.message"
                  )
                ) +
                " "
            ),
          ]
        ),
      ]),
      !_vm.isPc && _vm.hasTodayTicket
        ? _c(
            "div",
            { staticClass: "mypage-ticket-list-pane__today" },
            [
              _c("atom-router-button", {
                attrs: {
                  linkText: _vm.$tc(
                    "MypagePage.MypageTicketListPage.todayTickets"
                  ),
                  to: "/mypage/ticket-today",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mypage-ticket-list-pane__body" },
        [
          _c("mypage-ticket-list-container-section", {
            attrs: { openFilterId: _vm.state.selectingFilterType },
            on: {
              onClickFilter: _vm.handleFilterClicked,
              onClickFilterOption: _vm.closeFilter,
            },
          }),
        ],
        1
      ),
      _vm.state.selectingFilterType
        ? _c("div", {
            staticClass: "mypage-ticket-list-pane__overlay",
            on: { click: _vm.closeFilter },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }