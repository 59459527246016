var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-ticket-today-pane",
      style: _vm.todayTicketScreenBgImageStyle,
    },
    [
      !_vm.confirmModalView
        ? _c(
            "div",
            { staticClass: "mypage-ticket-today-pane__current-time" },
            [_c("mypage-ticket-today-current-time-parts")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mypage-ticket-today-pane__container" },
        _vm._l(_vm.purchasedTodayAvailableTickets, function (ticketEvent) {
          return _c(
            "div",
            {
              key: ticketEvent.uniqueKey,
              staticClass: "mypage-ticket-today-pane__body",
            },
            [
              _c(
                "div",
                { staticClass: "mypage-ticket-today-pane__block" },
                [
                  _c("mypage-ticket-today-summary-section", {
                    attrs: { ticketEvent: ticketEvent },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mypage-ticket-today-pane__block" },
                [
                  _c("mypage-ticket-today-list-section", {
                    attrs: { ticketEvent: ticketEvent },
                    on: {
                      onAdmissionTicketClicked: _vm.admissionTicketClicked,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm.safeModalView
        ? _c("mypage-ticket-today-safe-modal-section", {
            on: {
              onClickSubmit: _vm.handleSubmitSafeModalClicked,
              onClickClose: _vm.handleCloseSafeModalClicked,
            },
          })
        : _vm._e(),
      _vm.confirmModalView
        ? _c("mypage-ticket-today-confirm-modal-section", {
            attrs: {
              ticketType: _vm.toBeUsedTicketInfo.ticketType,
              purchasedTicketInfo: _vm.toBeUsedTicketInfo.targetPurchasedTicket,
            },
            on: {
              onClickSubmit: _vm.handleSubmitConfirmModalClicked,
              onClickCancel: _vm.handleCancelConfirmModalClicked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }