var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "map-car-parts",
      class: [
        { "is-selected": _vm.selectedPlayer },
        { "is-disabled": _vm.carDisabled },
        { "is-mounted": _vm.carMounted.mount },
        { "is-animation": _vm.enableAnimation },
        { "is-even": _vm.rank % 2 === 0 },
      ],
      style: _vm.carPosition,
    },
    [
      _c("span", { staticClass: "map-car-parts__name" }, [
        _vm._v("#" + _vm._s(_vm.number) + " " + _vm._s(_vm.name)),
      ]),
      _c(
        "svg",
        {
          staticClass: "map-car-parts__line",
          attrs: {
            width: "75",
            height: "30",
            viewBox: "0 0 75 30",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M74.5 29.5L46.5 0.500001L0 0.5",
              stroke: "#D9D9D9",
              "stroke-width": "0.5",
            },
          }),
        ]
      ),
      _c(
        "svg",
        {
          staticClass: "map-car-parts__dots",
          attrs: {
            width: _vm.carDotsSize,
            height: _vm.carDotsSize,
            viewBox: _vm.carDotsViewBox,
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("circle", {
            attrs: {
              cx: _vm.carDotsCircleSizes,
              cy: _vm.carDotsCircleSizes,
              r: _vm.carDotsCircleSizes,
              fill: "#d9d9d9",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }