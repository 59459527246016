import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'
import VideoPlayerResult from '@/util/videoplayer/VideoPlayerResult'
import VideoPlayerInitOptionsType from '@/util/videoplayer/VideoPlayerInitOptionsType'
import {
  changeMovie,
  changePlaybackRate,
  exitNativePlayer,
  getLocalCacheSegmentPath,
  getLocalCacheURL,
  getMovieDuration,
  getPlayerCurrentTime,
  hideNativePlayer,
  initNativeViewOnCordova,
  pauseNativePlayer,
  playNativePlayer,
  setPlayerCurrentTime,
  showNativePlayer,
  getNativePlayerStatus,
  changeNativePlayerSize,
  changeNativePlayerPosition,
  zoomNativePlayerDisplay,
  changeDeepestLayerStyle,
  setPlayerVolume,
  getPlayerCurrentProgramDateTime,
  createDrawingLayer,
  changeOptionDrawingLayer,
  addTouchBeganEventListener,
  addPlayerEventListener,
  drawSeekbar,
  drawBase64Image,
  updateBase64Image,
} from '@/util/videoplayer/index'
import {
  MovieDurationEventType,
  NativePlayerStatusEventType,
  PlayerCurrentTimeEventType,
  VideoPlayerResponseType,
  VideoPlayerDrawingResponseType,
  VideoPlayerTouchEventResponseType,
  VideoPlayerPlayEventResponseType,
  VideoPlayerAddIsLiveResponseType,
  PlayerCurrentProgramDateTimeEventType,
} from '@/util/videoplayer/VideoPlayerResponseType'
import Logger from '@/util/logger/Logger'
import VideoPlayStatus from '@/util/videoplayer/VideoPlayStatus'
import VideoPlayerStatus from '@/util/videoplayer/VideoPlayerStatus'
import SignedCookie, { SignedCookieNames } from '@/@types/SignedCookie'
import { VideoPlayerClass, VideoPlayerType } from '@/util/videoplayer/VideoPlayerType'
import { VideoPlayerError, VideoPlayerErrorType } from '@/util/videoplayer/VideoPlayerError'
import { VideoPlayerViewClass, VideoPlayerViewType } from '@/util/videoplayer/VideoPlayerViewType'
import {
  VideoPlayerDrawingSeekbarArgmentType,
  VideoPlayerDrawingBase64ImageArgmentType,
} from '@/util/videoplayer/VideoPlayerDrawingArgumentType'
import FileConverter from '@/util/fileConverter/FileConverter'
import DeviceInfo from '@/util/DeviceInfo'

// 画像ロード用
import playIcon from '@/assets/img/icon/icon_play__highlight.png'
import pauseIcon from '@/assets/img/icon/icon_pause__highlight.png'

/**
 * ネイティブプレーヤーに指定するsignatureパラメタの型情報
 */
type SignatureType = {
  name: string
  value?: string
  domain: string
  path: string
}

/**
 * ネイティブプレーヤーを利用して動画再生を行うための機能を提供する。
 *
 * この機能を利用して動画プレーヤーを表示した場合、videoタグは利用せず、以下のプラグインを利用して動画再生を行う。
 * https://bitbucket.org/pitchbase/runedge-cordova-plugin-videoplayer
 *
 * このプラグインは、アプリが動作しているプラットフォームが提供する動画再生フレームワークを利用して動画再生を行う。
 * iOSの場合、AVFoundation、Androidの場合、https://github.com/google/ExoPlayer を利用する。
 *
 * ネイティブプレーヤーは、WebViewとは別のプロセスで動作するため、Javascriptからネイティブプレーヤーの各機能を呼び出す場合は、
 * util/videoplayer/index.ts に定義されたAPIを利用する。
 * ネイティブプレーヤーが提供するAPIについては、以下のURL・IF仕様書を参照のこと。
 * https://pitchbase.atlassian.net/wiki/spaces/RD/pages/1516667249/Cordova+I+F
 * https://bitbucket.org/pitchbase/pitchbase-player-documents/src/master/player-documents/Api%E4%BB%95%E6%A7%98%E6%9B%B8/VideoPlayer%E3%83%97%E3%83%A9%E3%82%B0%E3%82%A4%E3%83%B3_API%E4%BB%95%E6%A7%98%E6%9B%B8.xlsx を参照のこと
 *
 * ネイティブプレーヤーを利用した場合、util/localcache/LocalCache.ts が提供する動画の先読み機能が利用可能となる。
 * @see LocalCache
 *
 */
export default class NativeVideoPlayer implements IVideoPlayer {
  /**
   * 対象のネイティブプレーヤーの識別番号
   */
  private target = 0

  /**
   * 動画プレーヤの表示形式
   * - Default
   *  通常のプレーヤ
   * - HighlightPreview
   *  ハイライトプレビュー
   */
  private viewType: VideoPlayerViewType = VideoPlayerViewClass.Default

  /**
   * 動画プレーヤー起動/映像切り替え時のタイムアウト
   * @private
   */
  private timeout = 0

  /**
   * 動画の開始位置。
   */
  private startTime = 0

  /**
   * 動画URL
   */
  private videoUrl?: string

  /**
   * 動画プレーヤーの初期サイズ
   */
  private initSize?: { width: number; height: number }

  /**
   * 再生対象の動画がライブ配信動画かどうか
   */
  private live = false

  /**
   * 動画プレーヤーの初期化が完了しているかどうか
   */
  private playerInitialized = false

  /**
   * 音量をミュートする前に指定していた音量の値を保持する
   * @private
   */
  private beforeMutedVolume = 0

  /**
   * タイムコードが未取得の状態の場合にタイムコード取得を繰り返すフラグ
   * @private
   */
  private isRepeatingGetCurrentTime = false

  /**
   * ハイライトプレビュー用状態
   * @private
   */
  private highlightPreviewState: {
    /**
     * 再生状態
     */
    play: boolean
    /**
     * 描画レイヤ生成済みフラグ
     */
    drawingLayerCreated: boolean
    /**
     * シークバーレイヤ生成済みフラグ
     */
    seekbarLayerCreated: boolean
    /**
     * 再生ボタンのBase64文字列
     */
    playBase64: string | null
    /**
     * 一時停止ボタンのBase64文字列
     */
    pauseBase64: string | null
    /**
     * 再生・一時停止表示のハンドルID
     */
    playPauseImageHandleID: string | null
    /**
     * 再生・一時停止表示の表示位置
     */
    playPauseButtonOrigin: { x: number; y: number }
    /**
     * 再生・一時停止表示の表示サイズ
     */
    playPauseButtonSize: { width: number; height: number }
    /**
     * シークバー描画引数
     */
    seekbarArgment: VideoPlayerDrawingSeekbarArgmentType
  } = {
    play: true,
    drawingLayerCreated: false,
    seekbarLayerCreated: false,
    playBase64: null,
    pauseBase64: null,
    playPauseImageHandleID: null,
    playPauseButtonOrigin: {
      x: 0,
      y: 0,
    },
    playPauseButtonSize: {
      width: 0,
      height: 0,
    },
    seekbarArgment: {
      origin: {
        x: 0,
        y: 0,
      },
      size: {
        width: 0,
        height: 0,
      },
    },
  }

  /**
   * 動画を最後まで再生し、再生が終了した場合に呼び出されるコールバック関数
   */
  private onPlayFinished?: () => void

  /**
   * 動画の再生状態(PLAY / PAUSE)が変化した場合に呼び出されるコールバック関数
   */
  private onPlayStatusUpdate?: (status: VideoPlayStatus) => void

  /**
   * 動画の再生可能状態が変化した場合に呼び出されるコールバック関数
   */
  private onPlayerStatusUpdate?: (status: VideoPlayerStatus, movieLength: number | null) => void

  /**
   * 映像の再生時刻が変更された場合に呼び出されるコールバック関数を指定する。
   * @param time 映像の再生時刻
   */
  private onCurrentTimeUpdate?: (time: number, videoTrackDateTime?: number) => void

  /**
   * 映像がシークされた場合に呼び出されるコールバック関数を指定する。
   * @param time 映像の再生時刻
   */
  private onSeeked?: (time: number) => void

  /**
   * 動画プレーヤーの初期化に失敗した場合に呼び出されるコールバック関数を指定する
   * @param error エラー定義
   */
  private onInitializeFailed?: (error: VideoPlayerErrorType) => void

  /**
   * 動画長が変化した場合に呼び出されるコールバック関数を指定する。
   * @param time 現在の動画長
   */
  private onChangeMovieLength?: (time: number) => void

  /**
   * 再生対象の動画のURLが変化した場合に呼び出されるコールバック関数を指定する。
   * @param movieUrl 現在の動画のURL
   */
  private onChangeMovieUrl?: (movieUrl: string) => void

  /**
   * 動画のcurrentTimeを定期取得するsetIntervalコールバック関数のID
   */
  private currentTimeIntervalId?: number

  /**
   * 動画のmovieLengthを定期取得するsetIntervalコールバック関数のID
   */
  private trackMovieLengthIntervalId?: number

  /**
   * 動画の再生位置が記録された実時間を取得する処理を行うかどうかを指定する。
   * 指定した場合、 onCurrentTimeUpdate コールバック関数のvideoTrackDateTimeパラメタに、
   * 動画の再生位置が記録された実時間が指定されるようになる。
   */
  readVideoTrackTime = false

  /**
   * シークの操作内容
   */
  private seekObject: {
    time: number
  } | null = null

  /**
   * 最新位置にシークするかどうか
   * @private
   */
  private seekToEnd = false

  /**
   * 現在再生している動画のURLを返す。
   */
  currentMovieUrl() {
    return this.videoUrl
  }

  /**
   * 現在の動画再生位置を返す。
   * @return 動画の再生位置(単位: 秒)
   */
  currentTime(): Promise<number> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#currentTime: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(0)
        return
      }

      if (this.seekObject !== null) {
        // シーク中は完了するまでシークで指定した時間を返す
        Logger.debug(
          `NativeVideoPlayer#currentTime: Return the time specified in the seek. seekObject.time: ${this.seekObject.time}`,
        )
        resolve(this.seekObject.time)
        return
      }

      getPlayerCurrentTime(
        this.target,
        (event: PlayerCurrentTimeEventType) => {
          // 取得途中にシークが開始された場合はシークで指定された時間を返す
          if (this.seekObject !== null) {
            resolve(this.seekObject.time)
          } else {
            resolve(Number(event.currentTime))
          }
        },
        (event: PlayerCurrentTimeEventType) => {
          const result = NativeVideoPlayer.dispatchError(event)
          Logger.info(`NativeVideoPlayer#currentTime: Failed to get currentTime. result: ${result}`)
          reject(result)
        },
      )
    })
  }

  /**
   * 現在再生している動画の再生位置が記録された実時間を取得する。
   * 動画にタイムコードが含まれている場合のみ取得可能。
   *
   * @return 現在再生している動画の再生位置が記録された実時間。UnixTime(単位: ミリ秒)
   * 取得できなかった場合、undefined を返す。
   */
  getCurrentVideoTrackTime(): Promise<number | undefined> {
    return new Promise((resolve) => {
      if (!this.readVideoTrackTime) {
        resolve(undefined)
        return
      }

      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#currentTime: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(undefined)
        return
      }

      getPlayerCurrentProgramDateTime(
        this.target,
        {},
        (event: PlayerCurrentProgramDateTimeEventType) => {
          const currentProgramDateTime = Number(event.currentProgramDateTime)
          if (Number.isNaN(currentProgramDateTime)) {
            resolve(undefined)
          } else {
            Logger.info(
              `NativeVideoPlayer#currentTime: Success to get currentProgramDateTime. currentProgramDateTime: ${currentProgramDateTime} valueState : ${event.valueState}`,
            )

            if (event.valueState === 'estimated') {
              // 予測値の場合、時間取得を繰り返す
              this.isRepeatingGetCurrentTime = true
            }
            resolve(currentProgramDateTime)
          }
        },
        (event: PlayerCurrentProgramDateTimeEventType) => {
          const result = NativeVideoPlayer.dispatchError(event)
          // 動画にpic_timingが存在しない場合はログ出力はしない
          if (!event.errorCode.endsWith('017')) {
            Logger.info(
              `NativeVideoPlayer#currentTime: Failed to get currentTime. result: ${result}`,
            )
          }
          resolve(undefined)
        },
      )
    })
  }

  /**
   * シーク中かどうかを判定する
   */
  isSeeking() {
    return !!this.seekObject
  }

  /**
   * 動画の長さを取得する。
   * @return 動画の長さ（秒）
   */
  duration(): Promise<number> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#duration: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(0)
        return
      }
      getMovieDuration(
        this.target,
        null,
        null,
        (event: MovieDurationEventType) => {
          Logger.debug(
            `FLUXNativeMoviePlayer#duration: Success getting movie duration. event=${event}, duration:${event.duration}`,
          )
          // ライブ配信の場合、duration が返す値の位置にシークした場合、まだ動画データがなく、シーク完了後に再生が止まってしまうため、少し前の位置(6秒前)を動画長とする
          let duration = event.duration ? Number(event.duration) : 0
          duration = this.live ? duration - 6.0 : duration
          resolve(duration < 0 ? 0 : duration)
        },
        (event: MovieDurationEventType) => {
          const result = NativeVideoPlayer.dispatchError(event)
          Logger.info(`NativeVideoPlayer#duration: Failed to get movie duration. result: ${result}`)
          reject(event)
        },
      )
    })
  }

  /**
   * 動画プレーヤーを破棄する。
   * @return 動画プレーヤー破棄の処理の完了を待機するためのPromise
   */
  dispose(): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#dispose: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }

      this.stopCurrentTimeMonitoring()
      this.stopDurationMonitoring()

      exitNativePlayer(
        this.target,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#dispose: Success exit NativeVideoPlayer. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#dispose: Failed to exit NativeVideoPlayer. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 動画プレーヤーを初期化する。
   * @param options 動画プレーヤーの初期化オプション
   * @return 動画プレーヤーの初期化を待機するためのPromise
   */
  init(options: VideoPlayerInitOptionsType): Promise<VideoPlayerResult> {
    this.target = options.target || 0
    this.viewType = options.viewType || VideoPlayerViewClass.Default
    this.timeout = options.timeout || 0
    this.videoUrl = options.movieUrl
    this.initSize = { width: options.width, height: options.height }
    this.live = false
    this.onPlayFinished = options.onPlayFinished
    this.onPlayStatusUpdate = options.onPlayStatusUpdate
    this.onPlayerStatusUpdate = options.onPlayerStatusUpdate
    this.onCurrentTimeUpdate = options.onCurrentTimeUpdate
    this.onInitializeFailed = options.onInitializeFailed
    this.onChangeMovieLength = options.onChangeMovieLength
    this.onChangeMovieUrl = options.onChangeMovieUrl
    this.startTime = options.startTime ?? 0

    return new Promise((resolve, reject) => {
      const nativePlayerOptions = {
        target: this.target,
        mode: 0,
        movieURL: getLocalCacheURL(this.videoUrl),
        playlistServerURL: getLocalCacheSegmentPath(this.videoUrl),
        segmentServerURL: getLocalCacheSegmentPath(this.videoUrl),
        startTime: options.startTime ?? 0,
        endTime: options.endTime ?? 0,
        menuBarOffsetY: 0,
        addViewItem: 0,
        slowPlayRate: 0.5,
        fastPlayRate: 2.0,
        refreshToken: options.refreshToken,
        signatures: options.cookies
          ? NativeVideoPlayer.getPlayListSignatures(options.cookies)
          : null,
      }

      Logger.debug(
        `NativeVideoPlayer#init: Start the initialization of the video player. nativePlayerOptions: ${JSON.stringify(
          nativePlayerOptions,
        )}, readVideoTrackTime: ${options.readVideoTrackTime}`,
      )

      // naitiveではsetMovieUrlを通らないのでここで反映
      if (options.readVideoTrackTime !== undefined) {
        this.readVideoTrackTime = options.readVideoTrackTime
      }

      const showHighlightPreview = this.viewType === VideoPlayerViewClass.HighlightPreview
      if (showHighlightPreview) {
        this.prepareHighlightPreviewItems(options.x, options.y, options.width, options.height)
      }

      const volume = options.volume ?? 1.0
      initNativeViewOnCordova(
        nativePlayerOptions.movieURL,
        nativePlayerOptions.playlistServerURL,
        nativePlayerOptions.segmentServerURL,
        nativePlayerOptions.startTime,
        options.x,
        options.y,
        options.width,
        options.height,
        nativePlayerOptions.menuBarOffsetY,
        nativePlayerOptions.target,
        nativePlayerOptions.mode,
        nativePlayerOptions.addViewItem,
        nativePlayerOptions.endTime,
        nativePlayerOptions.slowPlayRate,
        nativePlayerOptions.fastPlayRate,
        async (response: VideoPlayerAddIsLiveResponseType) => {
          this.playerInitialized = true
          // 音量のミュート解除の際に、音量をミュート前に戻す際に使用する。
          // 現在は存在しないが、音量を変更するメソッドを実装する際には、変更する値を beforeMutedVolume に
          // 保持しないとミュート解除した際に、初期化した時の値に戻ってしまうので注意が必要。
          this.beforeMutedVolume = volume

          this.live = response.isLive.toLowerCase() === 'true'
          this.seekToEnd = options.seekToEnd ?? false

          this.onPlayerStatusUpdate?.('Running', await this.duration())

          if (showHighlightPreview) {
            this.addHighlightPreviewItems()
          }

          this.startCurrentTimeMonitoring()
          this.startDurationMonitoring()

          if (this.seekToEnd) {
            // 対象の動画がライブ配信動画 かつ サーキットモードトグルボタンで再生モードを切り替ていない場合、末尾にシークする
            this.seekEndOfVideo()
          }

          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#init: Success initialize NativeVideoPlayer. event: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          let error
          if (response.errorCode === 'vp_001_004') {
            error = VideoPlayerError.COMPATIBLE_VIDEO_NOT_FOUND
            // 動画ファイル取得がタイムアウト
            Logger.info(
              `NativeVideoPlayer#init: Video file acquisition timed out. result: ${result}`,
            )
          } else {
            error = VideoPlayerError.UNKNOWN_ERROR
            // 動画プレーヤーの初期化に失敗
            Logger.info(
              `NativeVideoPlayer#init: Failed to initialize the video player. result: ${result}`,
            )
          }
          this.onInitializeFailed?.(error)
          reject(result)
        },
        1,
        'AUTO',
        nativePlayerOptions.refreshToken,
        nativePlayerOptions.signatures,
        {
          volume,
          currentTime: options.currentTime ? options.currentTime - (options.startTime || 0) : 0,
          aspect: 'resizeAspect',
          zPosition: options.zPositionForNative ?? -1,
          timeout: this.timeout,
        },
      )
    })
  }

  /**
   * ハイライトプレビュー用プレーヤ起動前事前準備処理
   * @param x プレーヤ表示位置x
   * @param y プレーヤ表示位置y
   * @param width プレーヤ表示サイズ幅
   * @param height プレーヤ表示サイズ高さ
   */
  async prepareHighlightPreviewItems(x: number, y: number, width: number, height: number) {
    // 再生中に設定する
    this.highlightPreviewState.play = true

    // figma上の表示サイズ
    const figmaHeight = 183.94

    /**
     * 座標を実際の表示サイズに変換する
     * @param value 座標
     * @return 変換後の座標
     */
    function convertScale(value: number): number {
      return value * (height / figmaHeight)
    }

    const barSize = convertScale(2)
    const barCorrectionSize = convertScale(0.5)
    const touchSpace = 10
    // バータッチでシークさせない
    const barTouchRadius = 0
    const cursorRadius = convertScale(7)
    const cursorTouchRadius = convertScale(7) + touchSpace
    const offserDistance = Math.max(cursorTouchRadius, barTouchRadius)
    const LayerOffsetPos = {
      x: x - offserDistance,
      y: y + height - offserDistance - barSize / 2.0,
    }
    const layerSize = {
      width: offserDistance * 2 + width,
      height: offserDistance * 2,
    }

    // シークバー用レイアウト保存
    const barBackgroundStyle = {
      // バー背景
      type: 'Rect',
      lineWidth: 0,
      lineColor: {
        red: 0.0,
        green: 0.0,
        blue: 0.0,
        alpha: 0.0,
      },
      fillColor: {
        red: 0.0,
        green: 0.0,
        blue: 0.0,
        alpha: 1.0,
      },
      cornerRadius: 0.0,
      padding: {
        top: offserDistance - barSize / 2.0,
        left: offserDistance,
        bottom: offserDistance - barSize / 2.0,
        right: offserDistance,
      },
      lineCap: 'square',
    }
    const barForegroundStyle = {
      // バー前景
      ...barBackgroundStyle,
      fillColor: {
        // 色だけ違う
        red: 0.0,
        green: 0.858,
        blue: 0.65,
        alpha: 1.0,
      },
      padding: {
        // サイズずれ補正
        top: offserDistance - barSize / 2.0 - barCorrectionSize,
        left: offserDistance,
        bottom: offserDistance - barSize / 2.0 - barCorrectionSize,
        right: offserDistance,
      },
    }
    const cursolNormalStyle = {
      // カーソル
      type: 'Circle',
      lineWidth: 0,
      fillColor: {
        red: 1.0,
        green: 1.0,
        blue: 1.0,
        alpha: 1.0,
      },
      radius: cursorRadius,
    }

    this.highlightPreviewState.seekbarArgment = {
      origin: {
        x: 0,
        y: 0,
      },
      size: layerSize,
      backgroundStyle: {
        // 背景表示なし
        type: 'Rect',
        lineWidth: 0.0,
        lineColor: {
          red: 0.0,
          green: 0.0,
          blue: 0.0,
          alpha: 0.0,
        },
        fillColor: {
          red: 0.0,
          green: 0.0,
          blue: 0.0,
          alpha: 0.0,
        },
      },
      barStyle: {
        // バー描画設定
        background: barBackgroundStyle,
        foreground: barForegroundStyle,
        touchRadius: barTouchRadius,
      },
      cursorStyle: {
        // カーソル描画設定
        normal: cursolNormalStyle,
        touchRadius: cursorTouchRadius,
      },
      options: {
        layerName: 'seekbarLayer',
      },
    }

    // ボタン表示用レイアウト保存
    this.highlightPreviewState.playPauseButtonOrigin = {
      x: convertScale(147.5),
      y: convertScale(76),
    }
    this.highlightPreviewState.playPauseButtonSize = {
      width: convertScale(32),
      height: convertScale(32),
    }

    // 再生・一時停止ボタン用レイヤー生成
    this.updateOrCreateDrawingLayer('drawingLayer', false, {
      isFadeout: true,
      fadeoutAtFirst: false,
      fadeoutAnimationTime: 0.3,
      zPosition: 1,
      hiddenWhenFadeout: true,
      scheduleLaunch: true,
    }).then(() => {
      this.highlightPreviewState.drawingLayerCreated = true
    })
    // シークバーレイヤー生成
    this.updateOrCreateDrawingLayer('seekbarLayer', false, {
      isSeparation: true, // プレーヤとは分離する
      separationOrigin: LayerOffsetPos,
      separationSize: layerSize,
      dock: 'Left,Bottom', // プレーヤの左下と相対位置固定
      zPosition: 2,
      scheduleLaunch: true,
    }).then(() => {
      this.highlightPreviewState.seekbarLayerCreated = true
    })
  }

  /**
   * 再生・一時停止ボタンの画像更新を行う
   * @param playState 再生状態
   * @param playState 再生状態
   */
  async updateHighlightPreviewPlayPause(playState: boolean) {
    this.highlightPreviewState.play = playState
    if (
      this.highlightPreviewState.playPauseImageHandleID !== null &&
      this.highlightPreviewState.pauseBase64 !== null &&
      this.highlightPreviewState.playBase64 !== null &&
      this.highlightPreviewState.drawingLayerCreated
    ) {
      await this.drawBase64Image(
        {
          base64Image: this.highlightPreviewState.play
            ? this.highlightPreviewState.pauseBase64
            : this.highlightPreviewState.playBase64,
          origin: this.highlightPreviewState.playPauseButtonOrigin,
          size: this.highlightPreviewState.playPauseButtonSize,
          options: {
            layerName: 'drawingLayer',
          },
        },
        this.highlightPreviewState.playPauseImageHandleID,
      )
    }
  }

  /**
   * ハイライトプレビュー用の表示アイテムを設定する
   */
  async addHighlightPreviewItems() {
    await Promise.all([
      // タッチイベント登録
      this.addTouchBeganEventListener(async (response: VideoPlayerTouchEventResponseType) => {
        Logger.debug(
          `NativeVideoPlayer#addTouchBeganEventListener: touch event response: ${JSON.stringify(
            response,
            null,
            ' ',
          )}`,
        )
        if (response.layerName !== 'seekbarLayer') {
          // 画面をタッチしたら、再生状態切り替え
          if (this.highlightPreviewState.play) {
            this.pause()
          } else {
            this.play()
          }
          // アイコン更新後にレイヤー表示を有効にする
          await this.updateHighlightPreviewPlayPause(!this.highlightPreviewState.play)
        }

        // フェイドアウトで消えるように設定しているので、タッチで再表示
        if (this.highlightPreviewState.drawingLayerCreated) {
          this.updateOrCreateDrawingLayer('drawingLayer', true, {
            isHidden: false,
          })
        }
      }),
      // 再生イベント登録
      this.addPlayerEventListener((response: VideoPlayerPlayEventResponseType) => {
        Logger.debug(
          `NativeVideoPlayer#addPlayerEventListener: play event response: ${JSON.stringify(
            response,
            null,
            ' ',
          )}`,
        )

        // シークバー操作による表示更新はしない
        if (response.cause !== 'seekbar control') {
          if (!this.highlightPreviewState.play && response.play === 'true') {
            // 再生になったら画像更新
            this.updateHighlightPreviewPlayPause(true)
          } else if (
            this.highlightPreviewState.play &&
            response.pause === 'true' &&
            response.cause !== 'reach end'
          ) {
            // 停止になったら画像更新
            // 終端の場合はループ再生するので更新しない
            this.updateHighlightPreviewPlayPause(false)
          }
        }
      }),
    ])

    // シークバー描画
    if (this.highlightPreviewState.seekbarLayerCreated) {
      this.drawSeekbar(this.highlightPreviewState.seekbarArgment)
    }

    // 再生と一時停止画像のロード
    const imageLoadPromises = []
    if (this.highlightPreviewState.playBase64 === null) {
      imageLoadPromises.push(
        FileConverter.imageToBase64(playIcon).then((base64) => {
          this.highlightPreviewState.playBase64 = base64.replace('data:image/png;base64,', '')
        }),
      )
    }
    if (this.highlightPreviewState.pauseBase64 === null) {
      imageLoadPromises.push(
        FileConverter.imageToBase64(pauseIcon).then((base64) => {
          this.highlightPreviewState.pauseBase64 = base64.replace('data:image/png;base64,', '')
        }),
      )
    }
    if (imageLoadPromises.length > 0) {
      await Promise.all(imageLoadPromises)
    }

    if (this.highlightPreviewState.drawingLayerCreated) {
      // 一時停止ボタン描画
      if (
        this.highlightPreviewState.playBase64 !== null &&
        this.highlightPreviewState.pauseBase64 != null
      ) {
        const base64ImageResult = await this.drawBase64Image({
          base64Image: this.highlightPreviewState.pauseBase64,
          origin: this.highlightPreviewState.playPauseButtonOrigin,
          size: this.highlightPreviewState.playPauseButtonSize,
          options: {
            layerName: 'drawingLayer',
          },
        })
        this.highlightPreviewState.playPauseImageHandleID = base64ImageResult.handleID
      }
    }
  }

  /**
   * プレーヤの再生時間監視開始
   */
  startCurrentTimeMonitoring() {
    this.stopCurrentTimeMonitoring()
    if (this.onCurrentTimeUpdate) {
      let lastCurrentTime = 0
      this.currentTimeIntervalId = window.setInterval(async () => {
        if (this.onCurrentTimeUpdate) {
          try {
            const currentTime = await this.currentTime()
            if (Math.abs(lastCurrentTime - currentTime) > 0.1 || this.isRepeatingGetCurrentTime) {
              this.isRepeatingGetCurrentTime = false
              const videoTrackDateTime = await this.getCurrentVideoTrackTime()

              // seekObjが更新されている場合があるのでここで更新
              let currentTimeTemp = currentTime
              if (this.seekObject !== null) {
                currentTimeTemp = this.seekObject.time
              }
              this.onCurrentTimeUpdate(currentTimeTemp, videoTrackDateTime)
              lastCurrentTime = currentTime
            }
          } catch (e) {
            Logger.warn(`NativeVideoPlayer#onCurrentTimeUpdate: Failed to get currentTime.`, e)
          }
        }
      }, 100)
      Logger.debug(`NativeVideoPlayer#init: Success initialize currentTimeUpdate callback.`)
    }
  }

  /**
   * プレーヤの再生時間監視停止
   */
  stopCurrentTimeMonitoring() {
    if (this.currentTimeIntervalId) {
      clearInterval(this.currentTimeIntervalId)
      this.currentTimeIntervalId = undefined
    }
  }

  /**
   * 動画長取得監視開始
   */
  startDurationMonitoring() {
    this.stopDurationMonitoring()
    if (this.onChangeMovieLength && this.live) {
      this.trackMovieLengthIntervalId = window.setInterval(async () => {
        if (this.onChangeMovieLength) {
          try {
            this.onChangeMovieLength(await this.duration())
          } catch (e) {
            Logger.warn(`NativeVideoPlayer#onChangeMovieLength: Failed to get duration.`, e)
          }
        }
      }, 1000)
    }
  }

  /**
   * 動画長取得監視停止
   */
  stopDurationMonitoring() {
    if (this.trackMovieLengthIntervalId) {
      clearInterval(this.trackMovieLengthIntervalId)
      this.trackMovieLengthIntervalId = undefined
    }
  }

  /**
   * 動画プレーヤーが初期化されているかどうかを返す。
   * @return 初期化されている場合 true を返す
   */
  initialized(): boolean {
    return this.playerInitialized
  }

  /**
   * 動画の再生を一時停止する。
   * @return 動画の再生一時停止処理の完了を待機するためのPromise
   */
  pause(): Promise<VideoPlayerResult> {
    return new Promise((resolve) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#pause: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug(`NativeVideoPlayer#pause: Pause video playback. target: ${this.target}`)
      pauseNativePlayer(
        [this.target],
        async (response: VideoPlayerResponseType) => {
          Logger.debug(
            `NativeVideoPlayer#pause: Video playback has been paused. target: ${this.target}`,
          )
          // 動画再生状態を呼び出し元に通知する
          await this.updatePlayStatus()

          resolve(NativeVideoPlayer.dispatchSuccess(response))
        },
        (response: VideoPlayerResponseType) => {
          Logger.debug(
            `NativeVideoPlayer#pause: Failed to pause video playback. target: ${this.target}`,
          )
          resolve(NativeVideoPlayer.dispatchError(response))
        },
      )
    })
  }

  /**
   * 動画を再生する。
   * @return 動画の再生開始処理の完了を待機するためのPromise
   */
  play(): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#play: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }

      Logger.debug(`VideoMoviePlayer#play: Play the movie. target: ${this.target}`)
      playNativePlayer(
        [this.target],
        /**
         * 動画が停止されたタイミングで呼び出される。
         * @param response ネイティブプレーヤーのAPIレスポンス。
         */
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#play: It played to the end of the video. result: ${result}`,
          )

          // 動画再生状態を呼び出し元に通知する
          this.updatePlayStatus().then(() => {
            // 動画の最後まで再生が完了したことを通知する
            if (response?.reason === 'reach end') {
              this.onPlayFinished?.()
              Logger.debug('NativeVideoPlayer#play: Success to play.')
            }
          })
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(`NativeVideoPlayer#play: Failed to play the video. result: ${result}`)
          reject(result)
        },
      )
      // 動画再生状態を呼び出し元に通知する
      this.updatePlayStatus().then(() => {
        Logger.debug(`NativeVideoPlayer#play: Success to play. target: ${this.target}`)
        // ネイティブプレーヤーのplayメソッドのsuccessのコールバック関数は、呼び出し完了時に呼び出されないため、
        // ここでresolveを呼び出す
        resolve(NativeVideoPlayer.dispatchSuccess())
      })
    })
  }

  /**
   * 動画の再生状態を取得する。
   */
  async getNativePlayerStatus(): Promise<NativePlayerStatusEventType['status']> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#getNativePlayerStatus: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve('error')
        return
      }
      Logger.debug('NativeVideoPlayer#getNativePlayerStatus: Start to get player status.')
      getNativePlayerStatus(
        this.target,
        async (response: NativePlayerStatusEventType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#getNativePlayerStatus: Success to get player status. status: ${response.status}, result: ${result}`,
          )
          resolve(response.status)
        },
        (response: NativePlayerStatusEventType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#getNativePlayerStatus: Failed to get player status. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 再生速度を設定する。
   * @param rate 再生速度
   */
  async setPlaybackRate(rate: number): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#setPlaybackRate: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug(`'NativeVideoPlayer#setPlaybackRate: Change playback rate. rate: ${rate}`)
      changePlaybackRate(
        rate,
        this.target,
        async (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#setPlaybackRate: Success to set playback rate. rate: ${rate}, result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#setPlaybackRate: Failed to set playback rate. rate: ${rate}, result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 動画のシークイベントのリスナーを登録する。
   * @param listener シークイベントのリスナー
   */
  addSeekEventListener(listener: (time: number) => void): void {
    this.onSeeked = listener
  }

  /**
   * 動画の再生位置を設定する。
   * @param time 再生位置(単位: 秒)
   * @return 動画再生位置設定処理の完了を待機するためのPromise
   */
  setCurrentTime(time: number): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#setCurrentTime: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }

      const relativeTime = time - this.startTime
      // シーク中
      const seekObject = { time: relativeTime }
      this.seekObject = seekObject

      Logger.debug('NativeVideoPlayer#setCurrentTime: Change current time of the video.')
      setPlayerCurrentTime(
        this.target,
        relativeTime,
        async (response: VideoPlayerResponseType) => {
          this.onSeeked?.(relativeTime)
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#setCurrentTime: Success to set current time. currentTime: ${relativeTime}, result: ${result}`,
          )

          // 多重更新防止のためポインタが同一の場合のみリセット
          if (this.seekObject === seekObject) {
            this.seekObject = null
            // シーク後にpic_timigを１度取得しにいく
            this.isRepeatingGetCurrentTime = true
          }
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#setCurrentTime: Failed to set current time. currentTime: ${relativeTime}, result: ${result}`,
          )

          // 多重更新防止のためポインタが同一の場合のみリセット
          if (this.seekObject === seekObject) {
            this.seekObject = null
          }
          reject(result)
        },
      )
    })
  }

  /**
   * 再生位置を動画の末尾に変更する。
   */
  async seekEndOfVideo() {
    const endOfVideoTime = await this.duration()
    return this.setCurrentTime(endOfVideoTime)
  }

  /**
   * 再生対象の動画URLを変更する。
   * init時に指定された動画がライブ配信動画の場合、ここで指定される動画もライブ配信動画として扱われる。
   * @param movieUrl 動画のURL
   * @param currentTime 動画URL設定後、この位置にシークする。未指定の場合、先頭位置にシークする
   * @param cookies 動画データを取得する際に付与する署名Cookie
   * @param readVideoTrackTime 動画の再生位置が記録された実時間を取得する処理を行うかどうか
   * @return 動画URL設定変更処理の完了を待機するためのPromise
   */
  async setMovieUrl(
    movieUrl: string,
    currentTime = 0,
    cookies?: Array<SignedCookie>,
    readVideoTrackTime = true,
  ): Promise<VideoPlayerResult> {
    if (!this.initialized()) {
      return NativeVideoPlayer.dispatchNotInitialized()
    }

    Logger.debug(
      `NativeVideoPlayer#setMovieUrl: Start to change movie url.
       movieUrl: ${movieUrl}, currentTime: ${currentTime},
       readVideoTrackTime: ${readVideoTrackTime}`,
    )

    this.readVideoTrackTime = readVideoTrackTime

    if (movieUrl === this.currentMovieUrl()) {
      // すでに同じURLが設定されており、再生可能になっている場合はシークのみを行う。
      const diff = Math.abs((await this.currentTime()) - currentTime)
      Logger.debug(`NativeVideoPlayer#setMovieUrl: currentTime diff: ${diff}`)
      if (diff > 0.3) {
        // 同じURL,同じシーク位置が指定された場合に、動画の再読み込みが実施されることを防ぐため、現在の再生位置とシーク位置の差が300ms以下の場合、シークしない。
        await this.setCurrentTime(currentTime)
      }
      return NativeVideoPlayer.dispatchSuccess()
    }

    return new Promise((resolve, reject) => {
      const movieInfo = {
        movieURL: getLocalCacheURL(movieUrl),
        playlistServerURL: getLocalCacheSegmentPath(movieUrl),
        segmentServerURL: getLocalCacheSegmentPath(movieUrl),
        strTime: 0,
        end_timestamp: 0,
        movieType: 'AUTO',
        signature: cookies ? NativeVideoPlayer.getPlayListSignatures(cookies) : null,
      }
      Logger.debug(
        `NativeVideoPlayer#setMovieUrl: Change url of the video. movieInfo: ${JSON.stringify(
          movieInfo,
        )}`,
      )

      // 時間監視一時停止
      this.stopCurrentTimeMonitoring()
      this.stopDurationMonitoring()

      this.onPlayerStatusUpdate?.('Updating', null)

      // changeMovieでシーク相当の動作をするのでここでもシークオブジェクトを操作する
      this.startTime = 0 // strTimeの値を反映する
      const relativeTime = currentTime - this.startTime
      // シーク中
      const seekObject = { time: relativeTime }
      this.seekObject = seekObject

      changeMovie(
        this.target,
        movieInfo,
        {
          currentTime,
          timeout: this.timeout,
        },
        async (response: VideoPlayerAddIsLiveResponseType) => {
          this.live = response.isLive.toLowerCase() === 'true'

          // 多重更新防止のためポインタが同一の場合のみリセット
          if (this.seekObject === seekObject) {
            this.seekObject = null
          }

          this.videoUrl = movieUrl
          // 再生可能となったことを通知する
          const movieDuration = await this.duration()
          const nowCurrentTime = await this.currentTime()

          this.onPlayerStatusUpdate?.('Running', movieDuration)
          this.onCurrentTimeUpdate?.(nowCurrentTime)

          // 時間監視再開
          this.startCurrentTimeMonitoring()
          this.startDurationMonitoring()

          // 動画再生状態を呼び出し元に通知する
          await this.updatePlayStatus()
          // 動画URL変更を呼び出し元に通知する
          this.onChangeMovieUrl?.(movieUrl)

          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#setMovieUrl: Success to change movie url. movieUrl: ${movieUrl}, currentTime: ${currentTime}, result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#setMovieUrl: Failed to change movie url. movieUrl: ${movieUrl}, currentTime: ${currentTime}, result: ${result}`,
          )
          // 多重更新防止のためポインタが同一の場合のみリセット
          if (this.seekObject === seekObject) {
            this.seekObject = null
          }
          reject(result)
        },
      )
    })
  }

  /**
   * 動画プレーヤーを表示する。
   * @return 表示処理完了を待機するためのPromise
   */
  show(): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#show: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug('NativeVideoPlayer#show: Display the video player.')
      showNativePlayer(
        this.target,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#show: Success to display the video player. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#show: Failed to display the video player. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 動画プレーヤーを非表示にする。
   * @return 非表示処理完了を待機するためのPromise
   */
  hide(): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#hide: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug('NativeVideoPlayer#hide: Hide the video player.')
      hideNativePlayer(
        this.target,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#hide: Success to hide the video player. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(`NativeVideoPlayer#hide: Failed to hide the video player. result: ${result}`)
          reject(result)
        },
      )
    })
  }

  /**
   * 音量をミュートにする。
   * @param shouldMute 音量をミュートにするかミュートを解除するかどうか、ミュートにする場合にはtrueを指定する
   * @return 音量をミュートにする処理の完了を待機するためのPromise
   */
  muted(shouldMute: boolean): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#muted: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug(`NativeVideoPlayer#muted: muted the video player. shouldMute: ${shouldMute}`)
      if (shouldMute) {
        setPlayerVolume(
          this.target,
          0,
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#muted: Success to mute the video player. result: ${result}`,
            )
            resolve(result)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#muted: Failed to mute the video player. result: ${result}`,
            )
            reject(result)
          },
        )
      } else {
        setPlayerVolume(
          this.target,
          this.beforeMutedVolume,
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#muted: Success to un unmute the video player. result: ${result}`,
            )
            resolve(result)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#muted: Failed to unmute the video player. result: ${result}`,
            )
            reject(result)
          },
        )
      }
    })
  }

  /**
   * シークバーによる音量設定
   * @param volumeLevel 音量
   */
  volume(volumeLevel: number): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!this.initialized()) {
        Logger.debug(
          `NativeVideoPlayer#volume: NativeVideoPlayer has not been initialized. target: ${this.target}`,
        )
        resolve(NativeVideoPlayer.dispatchNotInitialized())
        return
      }
      Logger.debug(`NativeVideoPlayer#volume: volume the video player. shouldMute: ${volumeLevel}`)
      // 音量が0の時はmutedメソッドの処理が走るため、音量が0より大きい場合に以下の音量調整する処理を実行する
      if (volumeLevel) {
        setPlayerVolume(
          this.target,
          volumeLevel,
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#volume: Success to volume the video player. result: ${result}`,
            )
            resolve(result)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#volume: Failed to volume the video player. result: ${result}`,
            )
            reject(result)
          },
        )
      }
    })
  }

  /**
   * 動画プレーヤーを、指定された表示サイズに変更し、表示位置を移動する。
   * @param to 表示位置
   * @param size 表示サイズ
   * @return 非表示処理完了を待機するためのPromise
   */
  async changeDisplaySize(
    to: { x: number; y: number },
    size: { width: number; height: number },
  ): Promise<VideoPlayerResult[]> {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.changeSize(size),
        this.changePosition(to),
        this.changeScale(size.width / (this.initSize?.width || size.width), { x: 0, y: 0 }),
      ]).then((results) => {
        const failedResults = results.filter((result) => result.status !== 'success')
        if (failedResults.length > 0) {
          Logger.info(
            `NativeVideoPlayer#changeDisplaySize: Failed to change display size. results: ${failedResults}`,
          )
          reject(failedResults)
          return
        }
        Logger.info(
          `NativeVideoPlayer#changeDisplaySize: Success to change display size. result: ${results}`,
        )
        resolve(results)
      })
    })
  }

  /**
   * 動画プレーヤーの表示サイズの変更
   * @param size 変更後の幅と高さ
   * @return サイズ変更処理完了を待機するためのPromise
   */
  changeSize(size: { width: number; height: number }): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      changeNativePlayerSize(
        this.target,
        size,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#changeNativePlayerSize: Success to change native player size. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#changeNativePlayerSize: Failed to change native player size. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 動画プレーヤーの表示倍率の変更
   * @param scale 表示倍率
   * @param displayPosition 表示位置情報
   * @return 表示倍率変更処理完了を待機するためのPromise
   */
  public changeScale(
    scale: number,
    displayPosition: { x: number; y: number },
  ): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      zoomNativePlayerDisplay(
        this.target,
        scale,
        displayPosition,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#zoomNativePlayerDisplay: Success to zoom native player display. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#zoomNativePlayerDisplay: Failed to zoom native player display. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 動画プレーヤーの表示位置の変更
   * @param point 変更後のX座標とY座標
   * @return 表示位置変更処理完了を待機するためのPromise
   */
  changePosition(point: { x: number; y: number }): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      changeNativePlayerPosition(
        this.target,
        point,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#changeNativePlayerPosition: Success to change native player position. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#changeNativePlayerPosition: Failed to change native player position. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 最奥レイヤの背景色を変更する。
   * @param backGroundColor 背景色のスタイル
   */
  static changeDeepestLayerStyle(
    backGroundColor: {
      red: number
      green: number
      blue: number
      alpha: number
    } = {
      red: 0.0,
      green: 0.0,
      blue: 0.0,
      alpha: 1.0,
    },
  ): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!DeviceInfo.isNativeVideoPlayerAvailable()) {
        Logger.debug(`NativeVideoPlayer#changeDeepestLayerStyle: Skip change deepest layer style.`)
        resolve(new VideoPlayerResult('success', undefined))
        return
      }
      changeDeepestLayerStyle(
        {
          backGroundColor,
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#changeDeepestLayerStyle: Success to change deepest layer style. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#changeDeepestLayerStyle: Failed to change deepest layer style. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * 手書きレイヤー生成・更新
   * @param name レイヤ名称
   * @param options レイヤーオプション(参照:https://pitchbase.atlassian.net/wiki/spaces/RD/pages/3811869301/createDrawingLayer+changeOptionDrawingLayer)
   * @return 手書きレイヤー生成を待機するためのPromise
   */
  updateOrCreateDrawingLayer(
    name: string,
    isUpdate: boolean,
    options: {
      isHidden?: boolean
      isFollowDisplay?: boolean
      zPosition?: number
      isFadeout?: boolean
      fadeoutAtFirst?: boolean
      fadeoutTime?: number
      fadeoutAnimationTime?: number
      isSeparation?: boolean
      separationOrigin?: { x: number; y: number }
      separationSize?: { width: number; height: number }
      dock?: string
      hiddenWhenFadeout?: boolean
      scheduleLaunch?: boolean
    },
  ): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      if (!isUpdate) {
        // 新規作成
        createDrawingLayer(
          this.target,
          name,
          options,
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#createDrawingLayer: Success to create drawing layer. result: ${result}`,
            )
            resolve(result)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#createDrawingLayer: Failed to create drawing layer. result: ${result}`,
            )
            reject(result)
          },
        )
      } else {
        // オプション更新
        changeOptionDrawingLayer(
          this.target,
          name,
          options,
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#changeOptionDrawingLayer: Success to change option drawing layer. result: ${result}`,
            )
            resolve(result)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#changeOptionDrawingLayer: Failed to change option layer. result: ${result}`,
            )
            reject(result)
          },
        )
      }
    })
  }

  /**
   * タッチ開始イベントリスナー登録
   * API詳細 (https://bitbucket.org/pitchbase/pitchbase-player-documents : Api仕様書/VideoPlayerプラグイン_API仕様書.xlsx)
   * @param listener イベント通知先
   * @return タッチ開始イベントリスナー登録を待機するためのPromise
   */
  addTouchBeganEventListener(
    listener: (response: VideoPlayerTouchEventResponseType) => void,
  ): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      addTouchBeganEventListener(
        this.target,
        listener,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#addTouchBeganEventListener: Success to add touch began event listener. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#addTouchBeganEventListener: Failed to add touch began event listener. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * プレーヤ再生状態監視イベントリスナー登録
   * API詳細 (https://bitbucket.org/pitchbase/pitchbase-player-documents : Api仕様書/VideoPlayerプラグイン_API仕様書.xlsx)
   * @param listener イベント通知先
   * @return プレーヤ再生状態監視イベントリスナー登録を待機するためのPromise
   */
  addPlayerEventListener(
    listener: (response: VideoPlayerPlayEventResponseType) => void,
  ): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      addPlayerEventListener(
        this.target,
        listener,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(
            `NativeVideoPlayer#addPlayerEventListener: Success to add player event listener. result: ${result}`,
          )
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(
            `NativeVideoPlayer#addPlayerEventListener: Failed to add player event listener. result: ${result}`,
          )
          reject(result)
        },
      )
    })
  }

  /**
   * シークバー描画
   * API詳細 (https://bitbucket.org/pitchbase/pitchbase-player-documents : Api仕様書/VideoPlayerプラグイン_API仕様書.xlsx)
   * 時間表示は利用しないので引数からは除外
   * @param argments 表示オプション
   * @return シークバー描画を待機するためのPromise
   */
  drawSeekbar(argments: VideoPlayerDrawingSeekbarArgmentType): Promise<VideoPlayerResult> {
    return new Promise((resolve, reject) => {
      drawSeekbar(
        this.target,
        argments.origin,
        argments.size,
        argments.backgroundStyle,
        argments.barStyle,
        argments.cursorStyle,
        {
          // 時間表示はなし
          isHidden: true,
        },
        argments.options,
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchSuccess(response)
          Logger.debug(`NativeVideoPlayer#drawSeekbar: Success to draw seekbar. result: ${result}`)
          resolve(result)
        },
        (response: VideoPlayerResponseType) => {
          const result = NativeVideoPlayer.dispatchError(response)
          Logger.info(`NativeVideoPlayer#drawSeekbar: Failed to draw seekbar. result: ${result}`)
          reject(result)
        },
      )
    })
  }

  /**
   * Base64描画
   * API詳細 (https://bitbucket.org/pitchbase/pitchbase-player-documents : Api仕様書/VideoPlayerプラグイン_API仕様書.xlsx)
   * @param argments 表示オプション
   * @param handleID 描画先
   * @return シークバー描画を待機するためのPromise
   */
  drawBase64Image(
    argments: VideoPlayerDrawingBase64ImageArgmentType,
    handleID: string | null = null,
  ): Promise<VideoPlayerDrawingResponseType> {
    return new Promise((resolve, reject) => {
      if (handleID === null) {
        // 新規描画
        drawBase64Image(
          this.target,
          argments.base64Image,
          argments.origin,
          argments.size,
          0.0,
          argments.options,
          (response: VideoPlayerDrawingResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#drawBase64Image: Success to draw base64 image. result: ${result}`,
            )
            resolve(response)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#drawBase64Image: Failed to draw base64 image. result: ${result}`,
            )
            reject(result)
          },
        )
      } else {
        // 描画更新
        updateBase64Image(
          this.target,
          handleID,
          argments.base64Image,
          argments.origin,
          argments.size,
          0.0,
          argments.options,
          (response: VideoPlayerDrawingResponseType) => {
            const result = NativeVideoPlayer.dispatchSuccess(response)
            Logger.debug(
              `NativeVideoPlayer#updateBase64Image: Success to update base64 image. result: ${result}`,
            )
            resolve(response)
          },
          (response: VideoPlayerResponseType) => {
            const result = NativeVideoPlayer.dispatchError(response)
            Logger.info(
              `NativeVideoPlayer#updateBase64Image: Failed to update base64 image. result: ${result}`,
            )
            reject(result)
          },
        )
      }
    })
  }

  /**
   * 再生対象の動画のファイルを取得する際に必要となる署名情報を取得する。
   * 署名Cookie情報の中から、署名情報を取得し、ネイティブプレーヤーのsignatureパラメタに指定可能な形式にして返す。
   * @param cookies 署名Cookie
   * @return 署名情報
   */
  private static getPlayListSignatures(cookies: Array<SignedCookie>) {
    const authCookies = cookies
    const signatures: Array<SignatureType> = []
    SignedCookieNames.forEach((cookieName) => {
      const cookie = authCookies.find((authCookie) => authCookie[cookieName])
      if (cookie) {
        const signature = {
          name: cookieName as string,
          value: cookie[cookieName],
          domain: cookie.Domain,
          path: cookie.Path,
        }
        signatures.push(signature)
      }
    })
    Logger.debug(
      `NativeVideoPlayer#getPlayListSignatures: Video signature information retrieved. signatures: ${signatures}`,
    )
    return signatures
  }

  /**
   * 指定されたイベント情報を含むネイティブプレーヤーの実行成功結果を生成して返す。
   * @param response ネイティブプレーヤーが返却した実行結果
   * @return ネイティブプレーヤーの実行成功結果
   */
  private static dispatchSuccess(response?: VideoPlayerResponseType) {
    return new VideoPlayerResult('success', undefined, response)
  }

  /**
   * 指定されたイベント情報を含むネイティブプレーヤーの実行エラー結果を生成して返す。
   * @param response ネイティブプレーヤーが返却した実行エラー結果
   * @return ネイティブプレーヤーの実行エラー結果
   */
  private static dispatchError(response: VideoPlayerResponseType) {
    return new VideoPlayerResult('error', undefined, response)
  }

  /**
   * 指定されたイベント情報を含むネイティブプレーヤーの初期化未完了の結果を生成して返す。
   * @param response ネイティブプレーヤーが返却した実行エラー結果
   * @return ネイティブプレーヤーの初期化未完了の結果
   */
  private static dispatchNotInitialized(response?: VideoPlayerResponseType) {
    return new VideoPlayerResult('not initialized', undefined, response)
  }

  /**
   * 動画プレーヤーの種別を返す。
   */
  // eslint-disable-next-line class-methods-use-this
  get playerType(): VideoPlayerType {
    return VideoPlayerClass.Native
  }

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを指定する。
   * ネイティブプレーヤーは、動画のライブ状態を指定することはできないため、このメソッドが呼び出されても無視する
   *
   * @param live ライブ配信されている動画の場合、true を指定する
   */
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  set liveMode(live: boolean) {}

  /**
   * 再生対象の動画が、ライブ配信されている動画かどうかを判定する。
   *
   * @return ライブ配信されている動画の場合、true を返す
   */
  get liveMode(): boolean {
    return this.live
  }

  /**
   * 動画の再生状態(再生・一時停止)を取得し、onPlayStatusUpdateをコールバックする
   */
  private updatePlayStatus() {
    return this.getNativePlayerStatus().then((status) => {
      this.onPlayStatusUpdate?.(status === 'play' ? 'PLAY' : 'PAUSE')
    })
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `NativeVideoPlayer[target: ${this.target}}]`
  }
}
