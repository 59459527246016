import { now } from 'lodash'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { TranscodeStatus, TranscodeStatusType } from '@/@types/TranscodeStatusType'
import { PlaylistType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import Const from '@/util/Const'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'

/**
 * コンテンツ情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/452493379/01+API
 */
export default class ContentsInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContentsInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contents/info'

  idAttr = 'matchId'

  /**
   * 試合ID
   */
  matchId: null | string = null

  /**
   * 動画ID
   */
  movieId: null | string = null

  /**
   * アングルID
   * 固定で 'main' を返す。
   */
  angleId = 'main'

  /**
   * ライブ配信チャンネルID
   */
  channelId: null | string = null

  /**
   * プレイリストパス
   */
  playlistPath = ''

  /**
   * 動画長
   */
  movieLength: null | number = null

  /**
   * 撮影開始時刻（ミリ秒）
   */
  recordingStartTime: null | number = null

  /**
   * 撮影開始時刻の調整時間（ミリ秒）
   */
  adjustedRecordingStartTime: null | number = null

  /**
   * マルチビットレート用のプレイリスト
   */
  multiBitRatePlaylist: Array<PlaylistType> | null = null

  /**
   * トランスコードステータス
   */
  transcodeStatus: TranscodeStatusType | null = null

  /**
   * ライブ配信ステータス
   */
  liveStreamingStatus: 'Idle' | 'Broadcasting' | 'Stopped' | null = null

  /**
   * ハイライト情報。
   * この動画情報をハイライト再生する場合に利用される。
   */
  highlightPlayInfo: {
    /** ハイライト情報ID */
    highlightId: string
  } | null = null

  /**
   * 無線データ情報。
   * 無線データの開始時刻に動画再生位置を遷移する場合に利用される。
   */
  radioPlayInfo: RadioDataDocument | null = null

  /**
   * ライブ配信されている映像かどうかを判定する
   */
  get isLive() {
    return this.transcodeStatus === TranscodeStatus.Live
  }

  /**
   * 動画アップロード後、変換が完了しているかどうかを判定する
   */
  get isTranscodeCompleted() {
    return this.transcodeStatus === TranscodeStatus.Complete
  }

  /**
   * adjustedRecordingStartTime の値で調整された撮影開始時刻を取得する。
   */
  get recordingStartTimeCalculated() {
    if (this.adjustedRecordingStartTime) {
      return this.adjustedRecordingStartTime
    }
    return this.recordingStartTime || 0
  }

  /**
   * 映像配信中かどうかを判定する。
   */
  isBroadcasting() {
    if (this.isLive && this.recordingStartTime) {
      return now() - this.recordingStartTime > Const.LIVE_START_MARGIN
    }
    return this.transcodeStatus === TranscodeStatus.Complete
  }

  /**
   * 映像配信中の場合に、その映像をライブ配信しているかどうかを判定する
   */
  isLiveBroadcasting() {
    return this.liveStreamingStatus === 'Broadcasting'
  }

  /**
   * 映像配信中の場合に、その映像がライブ配信を終了しているかどうかを判定する
   */
  isLiveStopped() {
    return this.liveStreamingStatus === 'Stopped'
  }

  /**
   * 映像が未配信かどうかを判定する
   */
  isNotBroadcasting() {
    return !this.isBroadcasting()
  }

  /**
   * ハイライトの切り抜きが可能かどうか
   * ブラウザ版のみで利用する処理
   */
  canClipHighlightForPc() {
    return this.isTranscodeCompleted || (this.isLive && this.isLiveStopped())
  }

  /**
   * 再生対象のプレイリストのパスを取得する。
   * アングル動画情報とインタフェースをあわせるために定義されている。
   */
  get playlistUrl() {
    return this.playlistPath
  }

  /**
   * 動画のタイムコード情報が利用可能かどうかを判定する。
   * true の場合、タイムコードが利用可能。
   * 中継映像の場合、タイムコード情報は利用できないため、常に false を返す。
   */
  // eslint-disable-next-line class-methods-use-this
  get videoTrackTimeEnabled() {
    return false
  }

  /**
   * このクラスの文字列表現を取得する。
   * @return 文字列表現
   */
  toString() {
    return `ContentsInfoDocument[matchId: ${this.matchId}}]`
  }
}
